import './index.scss'
import {connect} from 'react-redux'
import React from 'react'
const classnames = require('classnames')

const EditorLog = (props) => {
  const {height, editorlog} = props;
  const heightPx = `${height}px`

  const message = editorlog.isValid ? "No errors found..." : editorlog.error;
  return (
    <pre className={classnames('editor-log', 'alert', {"alert-success": editorlog.isValid, "alert-danger": !editorlog.isValid} )}  style={{height: heightPx, marginBottom: 0}} >
      {message}
    </pre>
  )
}

const stateToProps = (state) => ({
  height: state.viewport.editorLogHeigh,
  editorlog: state.editorlog
})
export default connect(stateToProps)(EditorLog)