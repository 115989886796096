import './index.scss'
import React from 'react'

import {
  Link
} from 'react-router-dom'
const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="col-md-12 footer_content">&copy; {new Date().getFullYear() } - PASS - Pre-Approval Screening Service - <Link to="/termsofuse">Terms & Conditions</Link></div>
      </div>
    </footer>
  )
}

export default Footer