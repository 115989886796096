import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import {hideHelpModal} from '../../actions/modal'

class HelpModal extends React.Component {
  constructor(props) {
    super(props)
    this.onHide = this.onHide.bind(this)
  }

  componentDidMount() {
  }
  
  onHide(e) {
    this.props.hideHelpModal();
  }

  render() {
    const {modalType, modalProps} = this.props.modal
    const show = !!modalType;
    return (
      <Modal show={show} dialogClassName="help-modal" keyboard={true} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">{modalProps.desc}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {modalProps.text}
          </p>
        </Modal.Body>
      </Modal>)
  }
}

export default connect(
  (state, ownProps) => ({
    modal: state.modal
  }), {hideHelpModal}
)(HelpModal)