import api from "../services/api"
import types from './types'
import {fetchedData, fetchingData} from './index'



// export const setPublishAt = (edtionId, publishAt) => ({
//   type: types.SET_EDITION_PUBLISH_DATE,
//   editionId: edtionId,
//   publishAt: publishAt
// })

//new 

const setEditions = (editions) => ({
  type: types.ADMIN_SET_EDITIONS,
  editions
})


export const fetchEditionsByRule = (ruleId) => {
  return (dispatch, getState) => {
    return api.edition
      .getByRuleId(ruleId)
      .then(data => {
        dispatch(setEditions(data))
        return data;
      })
  }
}


export const setCurrentEditionId = (editionId) => ({
  type: types.SET_CURRENT_EDITION_ID,
  currentEditionId: editionId
})


const updateEdition = (edition) => ({
  type: types.ADMIN_UPDATE_EDITION,
  edition
})

export const saveWorkingCopy = (edition) => {
  return (dispatch, getState) => {
    return api.edition
      .saveWorkingCopy(edition)
      .then(data => {
        dispatch(updateEdition(data))
        return data;
      })
  }
}


export const localCodeUpdate = (editionId, code) => ({
  type: types.CHANGED_CODE_LOCAL,
  editionId,
  code
})


const addEdition = (edition) => ({
  type: types.ADMIN_ADD_EDITION,
  edition: edition
})

export const createNewEdition = (req) => {
  return (dispatch, getState) => {
    dispatch(fetchingData())

    return api.edition.createNew(req)
    .then((edition) => {
      dispatch(fetchedData())
      dispatch(addEdition(edition))
      return edition;
    })
  }
}


export const refreshById = (editionId) => {
  return (dispatch, getState) => {
    dispatch(fetchingData())

    return api.edition.fetchById(editionId)
    .then((edition) => {
      dispatch(updateEdition(edition))
      dispatch(fetchedData())
      return edition;
    })
  }
}

export const publishEdition = (req) => {
  return (dispatch) => {
    dispatch(fetchingData())
    return api.edition.publish(req)
      .then(edition => {
        dispatch(updateEdition(edition))
        dispatch(fetchedData())
      })
  }
} 

