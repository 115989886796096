import api from "../services/api"
import types from './types'
import {fetchedData, fetchingData} from './index'

//images
const setImages = (images) => ({
  type: types.SET_IMAGES,
  images: images
})

export const fetchImgs = () => {
  return (dispatch) => {
    dispatch(fetchingData())
    return api.img.fetchAll()
    .then(images => {
      dispatch(setImages(images))
      dispatch(fetchedData())
    })
  }
}

export const addImg = (imgForm) => {
  return (dispatch) => {
    return api.img.add(imgForm)
  }
}