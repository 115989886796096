import {getImgUrl} from '../../../common'
import './index.scss'
import React from 'react'
import { Modal, 
  Button, 
  Form, 
  FormGroup, 
  ControlLabel, 
  FormControl, 
  HelpBlock,
  Checkbox } from 'react-bootstrap'
import api from '../../services/api'
import isEmpty from 'lodash/isEmpty'
// require('es6-promise').polyfill();
require('isomorphic-fetch');


const FormField = ({name, label, defaultValue, type, errors, onChange, ...props}) => {

  return (
    <FormGroup controlId={`edit-rule-${name}`} validationState={errors[name] ? "error" : null}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl defaultValue={defaultValue} type={type} onChange={onChange} name={name} {...props} />
    {errors[name] && <HelpBlock>{errors[name]}</HelpBlock>}
  </FormGroup>
  )
}

export default class EditRule extends React.Component {
  constructor(props) {
    super(props)

    const {editRule} = props;

    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.isValid = this.isValid.bind(this)

    this.state = {
      editRule: editRule,
      file: null,
      imagePreviewUrl: '',
      errors: {},
      mode: editRule.Id ? 'edit' : 'add',
      isFetching: false
    }
  }

  onChange(e) {
    if(e.target.type == 'file') {
      
      let reader = new FileReader();
      let file = e.target.files[0];
  
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
  
      reader.readAsDataURL(file)
    } else {
    
      const name = e.target.name;
      let value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;
      value = e.target.type == 'text' ? value.trim() : value;

      this.setState((pState) => {      
        let newState = {[name]: value}
        return {
          editRule: Object.assign({}, pState.editRule, newState)
        }
      })
    }
  }


  handleSubmit(e) {
    e.preventDefault();
    
    const {isValid, errors} = this.isValid()
    this.setState({errors})

    if(isValid){
      
      let formData = new FormData();
      formData.append('photo.jpg', this.state.file);
      formData.append('data', JSON.stringify(this.state.editRule))

      this.setState({isFetching: true})
      api.saveRule2(formData)
        .then(data => {
          if(data.success) {
            this.props.onSaved(data.data);
          } else {
            this.setState({errors: data.errors})
          }
          this.setState({isFetching: false})
        })
    }
  }

  isValid() {
    let errors = {};
    let isValid = true;

    const {editRule, file} = this.state;

    if(!editRule.title) {
      errors.title = "This field is required"
    } 
    
    if(!errors.title 
      && editRule.title
      && editRule.title.length < 3 ) {
      errors.title = "Min lenght 3"
    }
      
    if(!editRule.desc) {
      errors.desc = "This field is required"
    }

    if(!(editRule.img || file)){
      errors.img = "This field is required"
    }


    return {isValid: isEmpty(errors), errors}

  }

  onSave() {
    this.props.onSave(this.state.editRule);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      editRule: nextProps.editRule,
      file: null,
      imagePreviewUrl: '',
      mode: nextProps.editRule.id ? 'edit' : 'add',
      errors: {},
      isFetching: false
    })
  }

  render() {
    let { show, onHide } = this.props;
    let {editRule, imagePreviewUrl, file, errors, mode, isFetching} = this.state;
    
    editRule = Object.assign({}, editRule);
    let help = '';
    const img = file ? 
        <img src={imagePreviewUrl} /> : (editRule.img ? <img src={getImgUrl(editRule.img)}/> : null)

    const isValida = isEmpty(errors);

    return (
      <Modal show={show} onHide={onHide}  bsSize="large" animation={false}>
        <Form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="edit-rule-modal-title">{mode == 'add' ? 'Add rule' : 'Edit rule'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <FormField 
              name="title"
              label="Title"
              defaultValue={editRule.title}
              type="text"
              errors={errors}
              onChange={this.onChange}
              maxLength="100"
            />

            <FormField 
              name="desc"
              label="Description"
              defaultValue={editRule.desc}
              type="text"
              errors={errors}
              onChange={this.onChange}
              maxLength="100"
            /> 

            <FormField 
              name="resSection"
              label="Responsible section"
              defaultValue={editRule.resSection}
              type="text"
              errors={errors}
              onChange={this.onChange}
              maxLength="255"
            /> 

            <FormGroup controlId="formControlsTextarea">
              <ControlLabel>Info</ControlLabel>
              <FormControl 
                componentClass="textarea" 
                placeholder="textarea" 
                name="info" 
                defaultValue={editRule.info} 
                onChange={this.onChange} />
           </FormGroup>

            <FormGroup controlId="edit-rule-img" validationState={errors.img ? "error" : null}>
              <div className="img-prev">
                {img}
              </div>
              <div className="img-upload">
                <ControlLabel>Front img (280x144px)</ControlLabel>
                <FormControl type="file" onChange={this.onChange} name="img" />
                {errors.img && <HelpBlock>{errors.img}</HelpBlock>}
              </div>
            </FormGroup>

            <FormGroup controlId="edit-rule-public">
              <ControlLabel>Is public</ControlLabel>
              <Checkbox onChange={this.onChange} name="isPublic" defaultChecked={editRule.isPublic}>
                Is public
              </Checkbox>
            </FormGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" bsStyle="primary" disabled={isFetching}>Save</Button>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}