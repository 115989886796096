// require('es6-promise').polyfill();
// require('isomorphic-fetch');
import restClient from '../common/restClient'

const checkResponseStatus = (response) => {
  if (response.status < 200 && response.status >= 400) {
    throw new Error(`Bad response status: ${response.status}`);
  }
  return response.json();
}

export default {
  init() {
    return new Promise((resolve, reject) => {
      restClient
        .get('/webapi/rule/init')
        .then(checkResponseStatus)
        .then(data => resolve(data))
        .catch(_ => {})
    })
  },

  project: {
    save(project) {
      return restClient
        .post('/webapi/project/save', project)
        .then(checkResponseStatus)
        .then((res) => res.data)
    },

    getByRuleId(ruleId) {
      return restClient
        .get('/webapi/project/rule/' + ruleId)
        .then(checkResponseStatus)
    }
  },

  calculation: {
    add(calculation) {
      return restClient
        .post('/webapi/calculation/add', calculation)
        .then(checkResponseStatus)
    }
  },

  edition: {
    fetchPreviewEdition(editionId) {
      return restClient
        .get(`/webapi/rule/preview/${editionId}`)
        .then(checkResponseStatus)
    }
  }
}