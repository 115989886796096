import React from 'react'
import {connect} from 'react-redux'
require('./index.scss')

const TermsOfUse = ({myDnvglUrl}) => {
  return (<div className="terms-of-use">
    <iframe frameBorder="0" scrolling="no" src={myDnvglUrl}></iframe> 
  </div>)
}

const stateToProps = (state) => ({
  myDnvglUrl: `${state.config.myDnvglUrl}/TermsOfUse/?serviceId=${state.config.serviceId}`
})

export default connect(stateToProps)(TermsOfUse)