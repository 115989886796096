import api from "../../services/api"
import types from './types'
import {setRules} from './rules'
import {setEditions} from './editions'

export const setCurrentRuleId = (id) => ({
  type: types.SET_CURRENTRULE,
  currentRule: id
})


export const fetchPreviewEdition = (editionId) => {
  return (dispatch) => {
    return api.edition.fetchPreviewEdition(editionId)
            .then((data) => {                            
              dispatch(setRules([data.rule]))
              dispatch(setEditions([data.edition]))    
              return data;
              
            })
  }
} 