import React from 'react'
import { connect } from 'react-redux'
import { setCurrentProject } from '../../actions/project'
import {updateCurrent} from '../../actions/current'
import alertify from 'alertify.js'
import { convertDate } from '../../../services/utils'
import ExtStateStore from '../../../services/ExtStateStore'
import _values from 'lodash/values';
require('./index.scss')

const classnames = require('classnames')
const Paginator = require("paginator");

class RuleSaves extends React.Component {
  constructor(props) {
    super(props)

    this.onSearchChange = this.onSearchChange.bind(this)
    this.filterProjects = this.filterProjects.bind(this);
    this.onLoadProjectClicked = this.onLoadProjectClicked.bind(this)
    this.onChangePage = this.onChangePage.bind(this)
    const pageSize = 10;
    this.paginator = new Paginator(pageSize);

    this.state = {
      searchString: '',
      isLoading: false,
      pagination: {
        currentPage: 1,
        pageSize
      }
    }
  }

  onSearchChange(e) {
    this.setState({ searchString: e.target.value });
  }

  filterProjects(projects) {
    const { searchString } = this.state;
    if (searchString)
      return projects.filter(p => p.name.indexOf(searchString) == 0)
    return projects;
  }


  onLoadProjectClicked(e, project) {
    e.preventDefault();

    alertify.confirm(`Are you sure that you want to load project: "${project.name}"`, () => {
      this.props.setCurrentProject({ id: project.id })
      this.props.changeEdition(project.editionId)
      
      ExtStateStore.restore(null, JSON.parse(project.state));
      alertify.success(`Project "${project.name}" loaded`)
    });
  }

  onChangePage(e, pageNr) {
    e.preventDefault()
    const newPagination = Object.assign({}, this.state.pagination);
    newPagination.currentPage = pageNr;
    this.setState({ pagination: newPagination })
  }

  render() {

    const { projectList: allProjects, currentProject, rule, edition } = this.props;
    const { currentPage, pageSize } = this.state.pagination;

    if (allProjects.length == 0)
      return <p>No saved projects</p>;

    const filteredProjects = this.filterProjects(allProjects);
    const paginationData = this.paginator.build(filteredProjects.length, currentPage)
    const visibleProjects = filteredProjects.slice(paginationData.first_result, paginationData.first_result + pageSize);
    let pages = [];
    for (var i = 0; i < paginationData.total_pages; i++) {
      let pNr = i + 1;
      pages.push(<li key={i}><a href="#" onClick={(e) => this.onChangePage(e, pNr)}>{i + 1}</a></li>)
    }

    return (
      <div className="col-savedProject rule-saves">
        <div className="savedProjects col-savedProject">
          <div className="savedProjects_head">
            {`${allProjects.length} saved projects which can be restored`}
            <div className="form-group savedProjects_search">
              <input type="search"
                className="form-control input-search"
                id="name"
                placeholder="Search projects..."
                onChange={this.onSearchChange} />
            </div>

            {paginationData.total_pages > 1 &&
              <ul className="pagination">
                {pages}
              </ul>
            }
          </div>

          {visibleProjects.map(p => (
            <div key={p.id} className={classnames("savedProjects_item", {'active': currentProject && currentProject.id == p.id})} onClick={(e) => this.onLoadProjectClicked(e, p)}>
              <p className="savedProjects_title">
                {p.name}
              </p>
              <p className="savedProjects_date updated">
                Saved {convertDate(p.updatedAt)}
              </p>
              <p className="savedProjects_date">
                Edition {convertDate(p.eCrtd)}
              </p>
            </div>
          ))}

          {paginationData.total_pages > 1 &&
            <ul className="pagination">
              {pages}
            </ul>
          }
        </div>
      </div>)
  }
}

const stateToProps = (state) => ({
  projectList: _values(state.projectList, ['id'], ['desc']).sort((a, b) => (b.id - a.id)),
  currentProject: state.currentProject,
  
  
})

export default connect(stateToProps, { setCurrentProject,updateCurrent })(RuleSaves)