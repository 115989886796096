import React, {Component} from 'react';
import {Glyphicon, FormGroup, ControlLabel, FormControl, HelpBlock, option, Table, Button} from 'react-bootstrap'

// Display it with a nice wrapper
function FieldGroup({ id, label, help,valids, ...props }) {
  return (
    <FormGroup validationState={valids}>
      <ControlLabel>{label}</ControlLabel>
      <FormControl {...props} />
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
}

// Parent class for default functionality
const empty = "Please provide an input"
class Item extends Component {
  // Converts the items input to a value to be used and checks if it is an
  // acceptable error or if an local error should be raised.
  static toValue(src, input) {
    return {
      value: input,
      error: (input == undefined || input == "") ? empty : ""
    }
  }

  update(obj) {
    this.props.onValueChanged(obj)
    // actions.changeState((s)=>{
    //   s.setValue(this.props.name, this.props.block, obj)
    // })
  }
}

// Handles an input that is a number (or equation to calculate number)
export class ItemNumber extends Item {
  render() {
    let v = this.props.value
    if(v == undefined)
      v = ""
    return (
      <FieldGroup
        type="text"
        label={this.props.desc}
        value={v}
        valids={this.props.error != undefined && this.props.error != "" ? "error" : null}
        onChange={(e)=>{this.onChange(e)}}
        placeholder={this.props.desc}
      />
    );
  }

  onChange(e) {
    this.update(e.target.value);
  }

  static toValue(src, input) {
    let s = {value: 0}
    let v = 1*input
    if(!isFinite(v)) {
      s.error = "Please enter a number"
    } else {
      s.value = v
    }
    if(src.ui.min != undefined && src.ui.min > s.value)
      s.error = "Please enter a number >= " + src.ui.min
    return s
  }
}

// Handles a dropdown for selection type of input
export class ItemDropDown extends Item {
  render() {
    let v = this.props.value
    if(v == undefined || v == "")
      v = 0
    let items = [];
    for(let i = 0; i < this.props.options.length; i++) {
      let o = this.props.options[i];
      items.push(<option key={i} value={i}>{this.props.options[i].text} </option>);
    }
    let cur = this.props.value
    if(cur == undefined && this.props.options.length > 0)
      cur = 0
    return (
      <FormGroup
        validationState={this.props.error != undefined && this.props.error != "" ? "error" : null}
    >
        <ControlLabel>{this.props.desc}</ControlLabel>
        <FormControl
          componentClass="select"
          value={v}
          onChange={(e,i,v)=>{this.onChange(e,i,v)}}
        >
          {items}
         </FormControl>
      </FormGroup>
    );
  }

  onChange(event) {
    this.update(event.target.value);
  }

  static toValue(src, input) {
    let s= {input: input};
    if(input == undefined || input == "")
      input = 0
    if(!(input >= 0))
      input = 0
    if(input > src.ui.options.length - 1)
      input = src.ui.options.length -1
    s.value = src.ui.options[input].value
    return s
  }
}

// Handles a short text input
export class ItemText extends Item {
  render() {
    let { value, error, desc } = this.props;
    value = value == undefined ? '' : value;
    error = error != undefined && error!= "" ? "error" : null;

    return (
      <FieldGroup
        type="text"
        valids={error}
        label={desc}
        value={value}
        onChange={(e)=>{this.onChange(e)}}
        placeholder={desc}
      />
    );
  }

  static toValue(src, v) {
    return v == undefined ? {value: "", error: "no input given"} : {value: v}
  }

  onChange(event) {
    this.update(event.target.value);
  }
}


// Allows the user to enter input as a table of varying size
export class ItemTable extends Item {
  render() {
    let columns = this.props.columns.map(c => {
      return (<th key={c.name}>{c.title}</th>)
    })
    let v = this.props.value
    if(v==undefined)
      v = [];
    let rows = v.map((r,i) => {
      let ii = i
      let cols = this.props.columns.map((c,j)=> {
        return (<td className={isFinite(r[c.name]) ? "" : "has-error"} key={j}>
          <FormControl
            type="text"
            value={r[c.name]}
            onChange={e => this.onChange(e, ii, c.name)}
          /></td>)
      })
      return (<tr key={i}>{cols}<td style={{verticalAlign: "middle"}}><Glyphicon onClick={() => {
        let v = this.props.value;
        v.splice(i,1);
        this.update(v);
      }} glyph="remove" /></td></tr>)
    })
    return (
      <FormGroup>
        <ControlLabel>{this.props.desc}</ControlLabel>
        <Table condensed>
          <thead>
            <tr>
              {columns}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
        <Button onClick={()=>{
            let v= this.props.value;
            if(v == undefined)
              v = []
            let obj = {};
            this.props.columns.map(c => obj[c.name] = "")
            v.push(obj)
            this.update(v)
        }}>Add Row</Button>
      </FormGroup>
    )
  }

  onChange(event, i, name) {
    let v = this.props.value
    v[i][name] = event.target.value
    this.update(v);
  }

  static toValue(src, v) {
    if(v == undefined)
      return {value: [], error: "no input given"}
    // For now we only allow numbers in the table, later maybe that should be a
    // option propery - but no use as of now
    let vv = [];
    let error = undefined
    for(let i = 0; i < v.length; i++) {
      vv[i] = {}
      for(let n in v[i]) {
        if(v[i].hasOwnProperty(n)) {
          if(!isFinite(v[i][n]))
            error = "Please enter a number"
          vv[i][n] = 1*v[i][n]
        }
      }
    }
    return {error: error, value: vv}
  }
}

export var Items = {
  "text": ItemText,
  "number": ItemNumber,
  "dropdown": ItemDropDown,
  "table": ItemTable,
}
