import './index.scss'
import React from 'react'
import { connect } from 'react-redux'
import alertify from 'alertify.js'
import values from 'lodash/values'
import EditorArea from '../EditorArea'
import EditorMenu from '../EditorMenu'

import max from 'lodash/max'
import isEmpty from 'lodash/isEmpty'

import {setCurrentEditionId, fetchEditionsByRule} from '../../actions/editions'
import {setCurrentRuleId, fetchRules} from '../../actions/rules'

class EditorPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { ruleId } = this.props.match.params;
    
    this.props.setCurrentRuleId(ruleId)
    this.props.fetchEditionsByRule(ruleId).then(editions => {
      var editionId = max(Object.keys(editions), function (o) { return editions[o]; });
      this.props.setCurrentEditionId(editionId) 
    })
    const rule =  this.props.rules[ruleId];   

    if(!rule) {
      this.props.fetchRules(ruleId)
    
    }
  }

  loadPage(ruleSlug, editionId) {
    let editionAlreadySet = false;
    this.props.fetchRuleBySlug(ruleSlug, (ruleObj) => {
      if (editionId == null) {
        if (ruleObj && ruleObj.editions) {

          if (ruleObj.editions.length > 0) {
            editionId = ruleObj.editions[ruleObj.editions.length - 1].id;
          } else {
            this.props.setEdition({});
            editionAlreadySet = true;
          }
        }
      }
      if (!editionAlreadySet)
        this.props.fetchEditionWithCodeById(editionId);
    });
  }

  render() {
    const { rules, currentEditionId} = this.props;
    if(isEmpty(rules) || !currentEditionId)
      return null;
   
    
    return (
      <div className="editor-page" style={{height: "100%"}}>
        <div className="col-md-2 editor-menu-placeholder" style={{height: "100%"}}>
          <EditorMenu />
        </div>
        <div className="col-md-10 editor-container-placeholder">
          <div>
              <EditorArea />         
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
   return {
     rules: state.adminRules.toJS(),
     currentEditionId: state.adminCurrent.currentEditionId
   }
}

export default connect(
  mapStateToProps,
  { setCurrentEditionId, fetchEditionsByRule, setCurrentRuleId, fetchRules }
)(EditorPage)
