export default class {
  
  constructor(rule, edition, program) {
    
    this.name = rule.slug;               //database value
    this.title = rule.title;
    this.edition = edition.id;           //database value
    this.desc = rule.desc;               //this should be from db
    this.info = rule.info;

    this._program = program; 
    this._blocks = program.src.app.interface.blocks;
  
    this.back = program.back;
    this.src = program.src;
  }

  iterBlocks(f) {
    this._program.iterBlocks(f)
  }

  intro() {
    return this._program.src.intro
  }

  run(state, output) {
    return this._program.run(state, output);
  }

  getBlockByName(name) {
    return this._blocks.find(b => b.name === name)
  }

  collect(state) {
    return this._program.collect(state);
  }

  
}