import './index.scss'
import React from 'react'
import {connect} from 'react-redux'
import {
  Link
} from 'react-router-dom'
 const AppNav = (props) => {
  const {user} = props;
  return (
    <nav className="navbar-collapse collapse no-padding">
      <div className="container navbar-container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          PASS administration panel
        </div>
        <div id="navbar" className="collapse navbar-collapse">
          <ul className="nav navbar-nav">
            <li className=""><a href="/">Front Home</a></li>
            <li className="active"><Link to="/admin">Rules Overview</Link></li>
            <li><Link to="/admin/submitted">Submitted</Link></li>
            <li><Link to="/admin/users">Users</Link></li>
          </ul>

          <ul className="nav navbar-nav pull-right">
            <li className="user-name">{user.name}</li>
            <li><a href="/account/signout/">Logout</a></li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

const stateToProps = (state) => ({
  user: state.user
})

export default connect(stateToProps)(AppNav) 