import { combineReducers } from 'redux'
import types from '../actions/types'
import {currentProject} from './currentProject'
import {projectList} from './projectList'
import {modal} from './modal'
import {rules} from './rules'
import {editions} from './editions'
import {current} from './current'
import {rules as adminRules} from '../../back/reducers/'
import {editions as adminEditions} from '../../back/reducers/'
import {config as adminConfig} from '../../back/reducers/'
import {user as adminUser} from '../../back/reducers/'
import {current as adminCurrent} from '../../back/reducers/'
import {fetching as adminFetching} from '../../back/reducers/'
import {viewport} from '../../back/reducers/'
import {editorlog} from '../../back/reducers/'
import {imgs} from '../../back/reducers/'

// FETCHING
const fetching = (preState = false, action) => {
  switch (action.type) {
    case types.FETCHING_DATA_REQUEST:
      return true
    case types.FETCHING_DATA_COMPLETED:
      return false
    default:
      return preState
  }
}

// //Rules info
// export const rulesInfo = (preState = [], action) => {
//   switch(action.type) {
//     case types.SET_RULEINFOS: 
//     return [...action.payload];
//     default:
//       return preState
//   }
// }

//User
export const user = (prevState = {}, action) => {
  switch(action.type) {
    case types.SET_USER:
      return Object.assign({}, prevState, action.payload)
    default:
    return prevState
  }
}

//Config
export const config = (preState = {}, action) => {
  switch(action.type) {
    case types.SET_CONFIG:
      return Object.assign({}, preState, action.config)
    default:
    return preState;
  }
}

// fetching
// viewport,
// editorlog
//Root
export const rootReducer = combineReducers({
  fetching,
  user,
  currentProject,
  projectList,
  modal,
  config,
  rules,
  editions,
  current,
  adminRules,
  adminEditions,
  adminConfig,
  adminUser,
  adminCurrent,
  editorlog,
  viewport,
  imgs,
  adminFetching
})
