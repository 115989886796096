import { List, Map } from 'immutable'
import { combineReducers } from 'redux'

import types from '../actions/types'

//Fetching
export const fetching = (preState = false, action) => {
  switch (action.type) {
    case types.ADMIN_FETCHING_DATA_REQUEST:
      return true
    case types.ADMIN_FETCHING_DATA_COMPLETED:
      return false
    default:
      return preState
  }
}

//Rules on admin landing page
export const rules = (preState = Map(), action) => {
  switch(action.type) {
    case types.ADMIN_SET_RULES: 
      return preState.merge(action.payload);
    default:
      return preState
  }
}

export const editions = (preState = Map(), action) => {
  switch(action.type) {
    case types.ADMIN_SET_EDITIONS: 
      return preState.merge(action.editions)
    case types.ADMIN_UPDATE_EDITION:
      return preState.set(action.edition.id.toString(), Map(action.edition))
    case types.CHANGED_CODE_LOCAL: 
      return preState.setIn([action.editionId.toString(), "code"], action.code)
    case types.ADMIN_ADD_EDITION:
      return preState.set(action.edition.id.toString(), Map(action.edition))
    default:
      return preState
  }
}

export const imgs = (preState = {}, action) => {
  switch(action.type) {
    case types.SET_IMAGES:
      return Object.assign({}, preState, action.images);
    default: 
      return preState;
  }
}

export const current = (preState = {}, action) => {
  switch(action.type) {
    case types.SET_CURRENT_RULE_ID:
      //return preState.set("currentRuleId", action.currentRuleId)
      return Object.assign({}, preState, {currentRuleId: action.currentRuleId })
      case types.SET_CURRENT_EDITION_ID:
      //return preState.set("currentEditionId", action.currentEditionId)
      return Object.assign({}, preState, {currentEditionId: action.currentEditionId})
      default:
      return preState;
  }
}

export const config = (preState = {}, action) => {
  switch(action.type) {
    case types.ADMIN_SET_CONFIG:
      return Object.assign({}, preState, action.config)
    default:
      return preState;
  }
}

export const user = (prevState = {}, action) => {
  switch(action.type) {
    case types.ADMIN_SET_USER:
      return Object.assign({}, prevState, action.user)
    default:
      return prevState
  }
}

export const viewport = (prevState = {}, action) => {
  switch(action.type) {
    case types.SET_VIEWPORT:
      return Object.assign({}, prevState, action.viewport)
    default:
      return prevState
  }
}

export const editorlog = (prevState = {isValid: true}, action) => {
  switch(action.type) {
    case types.SET_EDITORLOG:
      return Object.assign({}, prevState, action.editorlog)
    default:
      return prevState
  }
}


// export const rootReducer = combineReducers({
//   fetching,
//   rules,
//   editions,
//   current,
//   imgs,
//   config,
//   user,
//   viewport,
//   editorlog
// })