import restClient from '../../common/restClient'
// require('es6-promise').polyfill();
require('isomorphic-fetch');

const ADM_URL_PREFIX = '/webapiadmin/'

const createUrl = (suffix) => {
  return `${ADM_URL_PREFIX}${suffix}`;
}

const checkResponseStatus = (response) => {
  if (response.status < 200 && response.status >= 400) {
    throw new Error(`Bad response status: ${response.status}`);
  }
  return response.json();
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  init() {
    return restClient.get(createUrl('init'))
      .then(checkResponseStatus)
  },

  getRulesInfo() {
    return new Promise((resolve, reject) => {
      restClient
        .get(createUrl('rule/all'))
        .then(checkResponseStatus)
        .then(data => resolve(data))
    })
  },

  getRuleBySlug(ruleSlug) {
    return new Promise((resolve, reject) => {
      restClient  
        .get(createUrl(`rule/${ruleSlug}`))
        .then(checkResponseStatus)
        .then(data => resolve(data))
    })
  },

  // createWorkingCopy(req) {
  //   return new Promise((resolve, reject) => {
  //     restClient
  //       .post(createUrl('edition/savewc'), req)
  //       .then(checkResponseStatus)
  //       .then(data => resolve(data))
  //   })
  // },

  saveRule(rule) {
    return new Promise((resolve, reject) => {
      restClient
        .post(createUrl('rule/save'), rule)
        .then(checkResponseStatus)
        .then(data => resolve(data))
    })
  },

  validateRule(validateReq) {
    return restClient
      .post(createUrl('rule/validation'), validateReq)
      .then(checkResponseStatus)
  },

  saveRule2(ruleForm) {
    return restClient
      .sendForm(createUrl('rule/save2'), ruleForm)
      .then(checkResponseStatus)
  },

  listCalculations() {
    return restClient
      .get(createUrl('calculation/all'))
      .then(checkResponseStatus)
  },

  edition: {
    publish(editionReq) {
      return restClient
        .post(createUrl("edition/publish"), editionReq)
        .then(checkResponseStatus)
    },

    getByRuleId(ruleId) {
      return restClient
        .get(createUrl(`rule/${ruleId}/editions`))
        .then(checkResponseStatus)
    },

    saveWorkingCopy(edition) {
      return restClient
        .post(createUrl("edition/workingCopy"), edition)
        .then(checkResponseStatus)
    },

    createNew(edition) {
      return restClient
          .post(createUrl('edition/create'), edition)
          .then(checkResponseStatus)
  
    },
    
    fetchById(editionId) {
      return restClient
      .get(createUrl(`edition/${editionId}`))
        .then(checkResponseStatus)
    }
  },

  img: {
    fetchAll() {
      return restClient
        .get(createUrl('img/all'))
        .then(checkResponseStatus)
    },

    add(imgForm) {
      return restClient
        .sendForm(createUrl('img/add'), imgForm)
        .then(checkResponseStatus)
    },
  },

  user: {
    all() {
      return restClient
        .get(createUrl(`user/all`))
        .then(checkResponseStatus)
    },

    updateAdminFlag(userId, flag) {
      return restClient
        .post(createUrl('user/updateAdminFlag'), {userId, isSuperUser: flag})
        .then(checkResponseStatus)
    }

  }
}