import {connect} from 'react-redux'
import HelpModal from './HelpModal'
import React from 'react'

const MODAL_COMPONENTS = {
  'HELP_MODAL': HelpModal,
  /* other modals */
}

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} />
}

export default connect(
  state => state.modal
)(ModalRoot)