//require('es6-promise').polyfill();
import * as XLSX from 'xlsx';
import Excel from './Excel'

export const loadJsonFile = (file) => {
  return new Promise((resolve, reject) => {

    if(!file.name.match(/\.json$/) /*&& !file.name.match(/\.xlsx$/)*/) {
      reject("Tried to load file of unknown type")
      return
    }
    
    let reader = new FileReader();
    reader.onload = (e) => {
      let r = e.target.result;
      let obj = {}
      try {
        obj = JSON.parse(r)
      } catch(e) {
        console.error(e)
        reject("Could not decode json file")
      }
      resolve(obj)
    }
    reader.readAsText(file)
  })
}


export const loadExcelFile = (file) => {
  return new Promise((resolve, reject) => {
    if(!file.name.match(/\.xlsx$/)) {
      reject("Tried to load file of unknown type")
      return
    }
  
    let reader = new FileReader();
    reader.onload = (e) => {
      let r = e.target.result;
  
      var data = new Uint8Array(r)
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("")
      var wb = XLSX.read(bstr, {type:"binary"})
      let obj;
      try {
        obj = Excel.fromExcel(wb)
      } catch(e) {
        reject("Could not read xlsx: " + e)
        return
      }
      resolve(obj)
    }
    reader.readAsArrayBuffer(file)
  })
  
}