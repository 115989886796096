import keyBy from 'lodash/keyBy'
import { createReducer } from 'redux-create-reducer';
import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux'
import types from '../actions/types'

const rulesByIds = (state = {}, action) => {
  return state;
}

const rulesAllIds = (state = [], action) => {
  return state;
}

const setRules = (state, action) => {
  const {payload} = action;

  const rulesByIds = keyBy(action.payload, 'id');
  const updatedRules = Object.assign({}, state.byIds, rulesByIds);
  const updateIds = action.payload.map(i => i.id);
  return {byIds: updatedRules, allIds: updateIds}
}

export const rules = reduceReducers(
  combineReducers({
    byIds: rulesByIds,
    allIds: rulesAllIds
  }),
  createReducer({}, {
    [types.SET_RULES]: setRules
  })
);
