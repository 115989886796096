import './index.scss'
import { Table } from 'react-bootstrap'
import React from 'react'
import api from '../../services/api'
import AppNav from '../AppNav';

const moment = require('moment');

const DATETIME_FORMAT = "YYYY.MM.DD HH:mm:ss"
export default class SubmittedPage extends React.Component {
  constructor(props) {
    super(props)
  
    this.state = {
      calculations: []
    }
  }
  
  componentDidMount() {
    api
      .listCalculations()
      .then(data => {
        this.setState({calculations: data})
      })
  }

  render() {
    const {calculations} = this.state;
    return (
      <div>  
        <AppNav/>
            <div className="submitted-page">
        <Table striped condensed hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Id</th>
                <th>Version</th>
                <th>Created By</th>
                <th>Email</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {calculations.map((c, idx) => (
                <tr key={c.id}>
                  <td>{idx + 1}</td>
                  <td>{c.id}</td>
                  <td>{c.edition}</td>
                  <td>{c.createdBy}</td>
                  <td>{c.createdByEmail}</td>
                  <td className="rulesDate">{moment.unix(c.createdAt).format(DATETIME_FORMAT)}</td>
                  <td><a href={`/webapiadmin/calculation/file/${c.id}`}>Download</a></td>
                </tr>))}
              </tbody>
          </Table>    
      </div>
      </div>
    )
  }
}