import './index.scss'
import React from 'react'
import RuleIntro from '../RuleIntro'
import RuleBlock from '../RuleBlock'
import RuleResults from '../RuleResults'
import RuleReport from '../RuleReport'
import RuleSubmission from '../RuleSubmission'

const RuleBody = ({ program, activeNav, tree, extStateStore, results, rebuild, rule, edition, gotosub, changeEdition, saveBlockClicked }) => {

  let contnet = '';
  switch (activeNav) {
    case 'intro':
      contnet = <RuleIntro text={program.intro()} rule={rule} edition={edition} changeEdition={changeEdition} />
      break;
  
    case "results":
      contnet = <RuleResults results={results} gotosub={gotosub}/>
      break;
      
    case "submission":
      contnet = <RuleSubmission edition={edition}/>
      break;
    default:
      let block = program.getBlockByName(activeNav)
      contnet = <RuleBlock program={program} block={block} tree={tree} extStateStore={extStateStore} rebuild={rebuild} saveBlockClicked={saveBlockClicked}/>
  }
  return (
    <div className="col-md-9 rule-body">
      {contnet}
    </div>
  )
}

export default RuleBody