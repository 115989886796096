import types from './types'

export const updateCurrent = (current) => ({
  type: types.UPDATE_CURRENT,
  payload: current
})


export const clearCurrent = (fields = 'all') => ({
  type: types.CLEAR_CURRENT,
  payload: fields
})