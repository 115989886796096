import ExtProgram from './ExtProgram'
import Program from './Old/Program'


const loadRuleSrcByPath = (config) => {
  return (path) => {
    return new Promise((resolve, reject) => {
      window.requirejs([path], function (apps) {
        resolve(apps);
      })
    })
  }
}


//TODO: not sure if this need to be deleted
// export const loadRule = (name) => {
//   return new Promise((resolve, reject) => {
//     loadRulesMetaData().then((rulesMeta => {

//       let promises = rulesMeta
//         .filter(rm => rm.name === name)
//         .map(rm => {
//           return new Promise((resolve, reject) => {
//             loadRuleSrcByPath()(rm.srcPath).then((src) => resolve(Object.assign({}, rm, { src })))
//           })
//         })

//       Promise.all(promises).then(resolve);
//     }))
//   })
// }

export const normalizeRules = (rules) => {
  return rules.reduce((prevVal, currVal) => {
    if (Object.keys(prevVal).indexOf(currVal.name) >= 0) {
      if (Object.keys(prevVal[currVal.name]).indexOf(currVal.ep) >= 0)
        throw `Two or more apps with the same name: '${currVal.name}' and edition: '${currVal.ep}'`;
      const tmp = Object.assign({}, prevVal[currVal.name], { [currVal.ep]: currVal }, { 'default': currVal.ep });
      return Object.assign({}, prevVal, { [currVal.name]: tmp });
    } else
      return Object.assign({}, prevVal, { [currVal.name]: { [currVal.ep]: currVal, default: currVal.ep } });
  }, {})
}


export const getDefaultSrc = (name, normalizeRules) => {
  const defaultEditionKey = normalizeRules[name].default;
  return normalizeRules[name][defaultEditionKey];
}


export const programFactory = (config) => {
  return {
    create: (rule, edition) => {
      return loadRuleSrcByPath()(`/code/${edition.id}`)
        .then(src => {
          //let extendedSrc = Object.assign({}, ruleInfo, { src })
          let extendedSrc = Object.assign({}, {name: rule.slug, ep: edition.id}, { src })
          let normalizedSrcs = normalizeRules([extendedSrc])
          
          //const defaultSrc = getDefaultSrc(ruleInfo.name, normalizedSrcs);
          const defaultSrc = getDefaultSrc(rule.slug, normalizedSrcs);
    
          //return new ExtProgram(ruleInfo.slug, ruleInfo.title, ruleInfo.editionId, ruleInfo.desc, ruleInfo.info, new Program(defaultSrc.src));
          return new ExtProgram(rule, edition, new Program(defaultSrc.src));  
        })
    }
  }
}