import './index.scss'
import React from 'react'
import {connect} from 'react-redux'
import Header2 from '../Header2'
import Footer from '../Footer'
import HomeContent from '../HomeContent'
import RuleContent from '../RuleContent'
import Modal from '../Modal'
import Preview from '../Preview'
import alertify from 'alertify.js'
import TermsOfUse from '../TermsOfUse'
import EditorPage from '../../../back/components/EditorPage'
import UserPage from '../../../back/components/UserPage'
import SubmittedPage from '../../../back/components/SubmittedPage'

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import RulePage from '../../../back/components/RulesPage'

const App = ({ user, rules, editions }) => {

  // if(isEmpty(user) || rules.length < 1 || isEmpty(editions.byIds))
  //   return null;

    var confirmationPrompt = (message, callback) => {
      alertify.confirm(message, function(){ callback(true) }
      , function(){ callback(false) })
    }

    return (
    <Router getUserConfirmation={confirmationPrompt}>
      <div className="app-holder">
        <Header2 user={user}/>
        <Switch>
          <Route key="admin" path="/admin" exact={true} component={RulePage} />
  `       <Route path="/admin/submitted"  component={SubmittedPage} />
          <Route path="/admin/users" component={UserPage} />
          <Route key="adminRule" path="/admin/rule/:ruleId" component={EditorPage} />

          <Route key="home" exact={true} path="/" component={HomeContent} />
          <Route key="termsofuse" path="/termsofuse" component={TermsOfUse} />
          <Route key="preview" path="/preview" exact={true} component={Preview} />
          <Route key="content" path={'/:ruleSlug'} component={RuleContent} />
          <Route  path={`/preview/:ruleSlug/:editionId`} element={RuleContent} /> 
        </Switch>
        <Footer />
        <Modal />
      </div>
    </Router>)
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    rules: state.rules.allIds.map(ruleId => state.rules.byIds[ruleId]),
    editions: state.editions
  }
}

export default connect(
  mapStateToProps,
)(App)


