import keyBy from 'lodash/keyBy'
import { createReducer } from 'redux-create-reducer';
import reduceReducers from 'reduce-reducers'
import { combineReducers } from 'redux'
import types from '../actions/types'

const editionsByIds = (state = {}, action) => {
  return state;
}

const editionsAllIds = (state = [], action) => {
  return state;
}

const setEditions = (state, action) => {
  const {payload} = action;

  const editionsByIds = keyBy(action.payload, 'id');
  const updatedEditions = Object.assign({}, state.byIds, editionsByIds);
  const updateIds = action.payload.map(i => i.id);
  return {byIds: updatedEditions, allIds: updateIds}
}

export const editions = reduceReducers(
  combineReducers({
    byIds: editionsByIds,
    allIds: editionsAllIds
  }),
  createReducer({}, {
    [types.SET_EDTIONS]: setEditions
  })
);
