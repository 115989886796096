export default {
  FETCHING_DATA_REQUEST: 'FETCHING_DATA_REQUEST',
  FETCHING_DATA_COMPLETED: 'FETCHING_DATA_COMPLETED',
  SET_RULEINFOS: 'SET_RULEINFOS',
  SET_USER: 'SET_USER',
  SET_CURRENTPROJECT: 'SET_CURRENTPROJECT',
  SET_PROJECT_LIST: 'SET_PROJECT_LIST',
  SET_CURRENTRULE: 'SET_CURRENTRULE',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',

  SET_CONFIG: 'SET_CONFIG',

  SET_RULES: 'SET_RULES',
  SET_EDTIONS: 'SET_EDTIONS',
  UPDATE_CURRENT: 'UPDATE_CURRENT',
  CLEAR_CURRENT: 'CLEAR_CURRENT'

}

export const modalTypes = {
  HELP_MODAL: 'HELP_MODAL' 
}
