import './index.scss'
import { orderBy } from "lodash";
import values from 'lodash/values'
import { connect } from 'react-redux'
import React from 'react'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { EditionType, EditionTypeRegular, EditionTypeWC } from '../../services/utils'
import { convertDate } from '../../../services/utils'
import { setCurrentEditionId, 
  publishEdition,
  fetchEditionsByRule } from '../../actions/editions'
import alertify from 'alertify.js'
const classNames = require('classnames')

class EditorMenu extends React.Component {
  constructor(props) {
    super(props)

    this.onLoadEditionClicked = this.onLoadEditionClicked.bind(this)
    this.onPreviewEditionClicked = this.onPreviewEditionClicked.bind(this)
    this.onPublishEditionClicked = this.onPublishEditionClicked.bind(this)

    this.onSearchChange = this.onSearchChange.bind(this)

    this.state = {
      searchString: ''
    }
  }

  //search
  onSearchChange(e) {
    this.setState({ searchString: e.target.value });
  }

  filterEditions(editions) {
    const { searchString } = this.state;
    if (searchString)
      return editions.filter(p => p.title.indexOf(searchString) == 0)
    return editions;
  }

  //actions
  onLoadEditionClicked(editionId) {
    this.props.setCurrentEditionId(editionId)
  }

  onPreviewEditionClicked(editionId) {
    const {currentRule} = this.props;
    window.open(`/preview/${currentRule.slug}/${editionId}`)
  }

  onPublishEditionClicked(editionId) {

    const { current, editions } = this.props;
    const currentEdition = editions.find(e => e.id == editionId/*current.currentEditionId*/);
    const currentRuleId = currentEdition.ruleId;

    alertify.confirm(`Are you sure you want to publish version: "${currentEdition.title}"`, () => {
      this.props.publishEdition({ editionId })
        .then(freshEdition => {
          //refresh list
          this.props.fetchEditionsByRule(currentRuleId).then(() => {
            alertify.success('Version published');
          })
        })
        .catch(data => {
          console.error(data)
          alertify.error('Can not publish')
        })

    })

  }

  render() {
    const { editions, current, currentRule } = this.props;
    const { currentEditionId } = current;
    let menuItems = editions;

    const workingCopy = menuItems.find(m => EditionType[m.type] == EditionTypeWC);
    const filtered = this.filterEditions(menuItems.filter(m => EditionType[m.type] == EditionTypeRegular))
    let mItems = orderBy(filtered, ['id'], ['desc'])

    if (workingCopy)
      mItems.unshift(workingCopy)


    const publishButton = (mi) => {
      let button = null;
      if (EditionTypeRegular == EditionType[mi.type]) {
        if (!mi.publishedAt) {
          button = <button className="btn btn-subtle btn-xs" onClick={(e) => { e.preventDefault(); this.onPublishEditionClicked(mi.id) }}><span className="glyphicon glyphicon-globe"></span> Publish</button>
        } else {
          button = <button className="btn btn-xs" disabled={true}><span className="glyphicon glyphicon-globe"></span> Published</button>
        }
      }

      return button;
    }

    let {menuHeight} = this.props.viewport;
    let menuHeightAsString = `${menuHeight}px`; 
    return (
      <div className="editor-menu" style={{height: "100%"}}>

        <FormGroup>
          <InputGroup className="search-input-group">
            <FormControl type="text" placeholder="Enter search text" onChange={this.onSearchChange} />
          </InputGroup>
        </FormGroup>

        <ul className="menu-list" style={{height: menuHeightAsString, overflowY: "scroll"}}>
          <div className="right-side">

            {mItems.map(mi => (<li key={mi.id} className={classNames({ "workingCopy": mi.type == 1, "current": mi.id == currentEditionId })}>
              <span className="glyphicon glyphicon-globe glyphicon-title"></span>
              <a href="#" onClick={(e) => { e.preventDefault(); this.onLoadEditionClicked(mi.id) }}>
                <span className='name'>{mi.title}</span></a>
              <div className="info">
                <div className='created-at'>Created at: {convertDate(mi.createdAt)}</div>
                <div className='created-by'>Created by: {mi.createdBy}</div>
                {EditionTypeWC == EditionType[mi.type] &&
                  <div>
                    <div className='created-at'>Update at: {convertDate(mi.updatedAt)}</div>
                    <div className='created-by'>Update by: {mi.updatedBy}</div>
                  </div>
                }
              </div>

              <div className="functions">
                <button className="btn btn-subtle btn-xs" onClick={(e) => { e.preventDefault(); this.onPreviewEditionClicked(mi.id) }}><span className="glyphicon glyphicon-eye-open"></span> Preview</button>
                {publishButton(mi)}
              </div>

            </li>))}
          </div>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    editions: values(state.adminEditions.toJS()).filter(e => e.ruleId == state.adminCurrent.currentRuleId),
    current: state.adminCurrent,
    viewport: state.viewport,
    currentRule: state.adminRules.get(state.adminCurrent.currentRuleId.toString())//.toJS()
  }
}

export default connect(
  mapStateToProps, { setCurrentEditionId, publishEdition, fetchEditionsByRule }

)(EditorMenu)