import api from '../../services/api'
import types from './types'
import { setConfig as setConfiguration } from '../../common'
import {setRules} from './rules'
import {setEditions} from './editions'

// Fetching data flag
export const fetchingData = () => ({
  type: types.FETCHING_DATA_REQUEST
})

export const fetchedData = () => ({
  type: types.FETCHING_DATA_COMPLETED
})

// Init action
export const init = () => {
  return (dispatch, getState) => {
    dispatch(fetchingData())

    api.init()
      .then(data => {
        dispatch(setConfig(data.data.config))
        //dispatch(setRulesInfo(data.data.rulesInfo))
        dispatch(setUser(data.data.user))
        dispatch(setRules(data.data.rules))
        dispatch(setEditions(data.data.editions))
        dispatch(fetchedData())
        //dispatch(fetchedInitDataSuccess(data));
      })
  }
}

// User
export const setUser = (data) => ({
  type: types.SET_USER,
  payload: data
})

const setConfig = (config) => {
  setConfiguration(config)

  return {
    type: types.SET_CONFIG,
    config
  }
}
