import * as XLSX from 'xlsx';
// A Small module to convert between state objects and Excel files such that
// we can support importing and exporting excel as requested.


export default class Excel {
  // Convert a state object into and excel file that can be downloaded by the
  // user and thus used to input data into the tool Returned as a blob
  static toExcel(state, int) {

    // Create a workbook
    var wb = {}
    wb.SheetNames = ["Input"]
    wb.Sheets = {Input: {}}
    var s= wb.Sheets["Input"]

    // Write all the static values
    s["A1"] = {v:"PASS", t:'s'}
    s["C1"] = {v:state.edition, t:'n'}      //changed edtion to number
    s["B1"] = {v:state.app, t:'s'}
    s["A2"] = {v:`This sheet is specifically formatted to be imported into the PASS, which cells contains information is thus strict such that the tool can interpret it. It has to fullfill the following requirements:
 1. This must be the first sheet in the workbook containing "PASS" in cell A1 and the name of the abb in B1, edition in C1
 2. The first data row starts on row 6
 3. The First column (A) is for descriptive purposes and arbitrary
 4. The second column (B) is the  block name  and must be exact and is Case Sensitive (Basic != basic)
 5. The second column (C) is the  variable name  and must be exact and is Case Sensitive (Basic != basic)
 6. The third column (D) is the values for the corresponding name
 7. If more than one copy of a value exists the corresponding number should be appended as " x", in the block name e.g. "basic 1", "basic  2", etc.
 8. If >= 10 Name rows after each other are blank, no further input is looked for.`, t:'s'}
    s["A5"] = {v:"Description:", t:'s'}
    s["B5"] = {v:"Block:", t:'s'}
    s["C5"] = {v:"Name:", t:'s'}
    s["D5"] = {v:"Value:", t:'s'}
    let row = 4
    // Loop through all data and push it into the excel sheet for input
    for(let i = 0; i < int.blocks.length; i++) {
      let b = int.blocks[i];
      for(let j = 0; j < b.items.length; j++) {
        let item = b.items[j]
        // Loop through it for each instance of this block in order
        let no = 1
        if(state.content.blocks[b.name] != undefined)
          no = state.content.blocks[b.name].no
        for(let k = 0; k < no; k++) {
            row++
            let val = ""
            if(state.content.data[item.name] != undefined &&
               state.content.data[item.name][k] != undefined)
              val = state.content.data[item.name][k]
            s[XLSX.utils.encode_cell({r:row, c:0})] =
              {v: item.desc, t: "s"}
            s[XLSX.utils.encode_cell({r:row, c:1})] =
              {v: b.name + (no == 1 ? "" : " "+ (k+1)), t: "s"}
            s[XLSX.utils.encode_cell({r:row, c:2})] =
              {v:item.name , t: "s"}
            s[XLSX.utils.encode_cell({r:row, c:3})] =
              {v:(typeof val === "object" ? JSON.stringify(val) : ""+val), t: "s"}
        }
      }
    }

    var range = {s: {c:0, r:0}, e: {c:1000, r:1000 }};
      s['!ref'] = XLSX.utils.encode_range(range)
    // Create it and convert it to a blob such that it can be downloaded.
    let out = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});
    var buf = new ArrayBuffer(out.length)
    var view = new Uint8Array(buf)
    for (var i=0; i!=out.length; ++i) view[i] = out.charCodeAt(i) & 0xFF;
    return new Blob([view],{type:"application/octet-stream"})
  }

  // Convert an excel file into a state object that can be loaded into the tool
  static fromExcel(wb) {
    let s = Excel.findSheet(wb)
    let it = Excel.iterator(s)

    let state = {app: s.app, edition: s.edition, content: {blocks:{}, data:{}}}

    for(let v = it(); v != undefined; v = it()) {
      // Each time we read an element, we check if it has a number appended, we then
      // set the corresponding block number ot be increased and finally add the item
      // to the correct data array. This will step by step generate a state
      // which is correct.
      if(state.content.blocks[v.block] == undefined)
        state.content.blocks[v.block] = {no:1}
      if(state.content.blocks[v.block].no < v.no)
        state.content.blocks[v.block].no = v.no

      let vv = v.value
      if(v.value.startsWith("[") || v.value.startsWith("{")) {
        try {
          vv = JSON.parse(v.value)
        } catch(e) {
          throw "found a value for name '" + v.name + "': " + e + " " + v.value
        }
      }

      if(state.content.data[v.name] == undefined)
        state.content.data[v.name] = []
      for(; state.content.data[v.name].length < v.no -1;)
        state.content.data[v.name].push("")
      state.content.data[v.name][v.no-1] = vv
    }
    return state
  }


  static iterator(s) {
    // An iterator should iteratre through the rows, until he finds ten blank After
    // each other, when next should return undefined
    let enc = XLSX.utils.encode_cell
    let curr = {r:5,c:3}
    let nob = 0 // number of blank
    return () => {
      for(;;) {
        if(nob > 10)
          return
        // read out the name and values
        let n = s[enc({r:curr.r, c:2})]
        let b = s[enc({r:curr.r, c:1})]
        let v = s[enc(curr)]
        curr.r++
        if(n==undefined || b==undefined || n.v == undefined || n.v == "" || b.v == undefined || b.v == "") {
          nob++
          continue
        }
        nob = 0
        if(v==undefined)
          v = {v:""}
        v.v += "" // coerce it to a string
        // Split the block name and number apart
        b.v = b.v.trim()
        n.v = n.v.trim()
        v.v = v.v.trim()
        let arr = b.v.split(" ")
        if(arr.length > 2)
          throw "encountered a non-sensical block name: '" + b.v + "'"
        let no = arr[1] == undefined ? 1 : arr[1]
        if(!isFinite(no) || no < 1)
          throw "encountered a block index with is not a number or >= 1: " + b.v

        b.v = arr[0]
        return {no: 1*no, block: b.v, name: n.v, value: v.v}
      }
    }
  }

  static findSheet(wb) {
    // Loop throuch the sheets and finc the first one with "cmcAPcellerator" in A1
    for(let i  = 0; i < wb.SheetNames.length; i++) {
      let s = wb.Sheets[wb.SheetNames[i]]
      if(s["A1"] != undefined && s["A1"].v=="PASS") {
        s.app = s["B1"]
        s.edition = s["C1"]
        if(s.app != undefined && s.app.v != undefined && s.app.v != "" &&
          s.edition != undefined && s.edition.v != undefined && s.edition.v != "") {
            s.app = s.app.v
            s.edition = s.edition.v
            return s
        }
      }
    }
    throw "found no datasheet"
  }
}
