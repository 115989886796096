import { Map } from 'immutable'
import types from '../actions/types'

export const projectList = (prevState = {}, action) => {
  switch(action.type) {
    case types.SET_PROJECT_LIST:
      return Object.assign({},  action.projectList)
    default:
    return prevState
  }
}