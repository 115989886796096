import './index.scss'
import React from 'react'
import RuleSubMenu from '../RuleSubMenu'
import {convertDate} from '../../../services/utils'

const RuleHeader = ({
    onSaveCurrentInput, 
    onSaveNewProject, 
    onDownloadInput,
    onDownloadExcel,
    onInportData,
    onGenerateReport, 
    currentProject,
    rule,
    edition,
    updateToDefaultClicked,
    onNewProject
  }) => {

  //var updateAvailable = rule.defaultEditionId != edition.id ? <span className="out-dated">Project's using diffrent version then default</span> : null;
  var updateAvailable = null;
  var updateEdition = rule.defaultEditionId != edition.id ? <div style={{display: "inline-block"}}><button type="button" className="btn btn-primary btn-inverse btn-small" style={{fontSize: "12px", padding: "4px 8px"}} onClick={updateToDefaultClicked}>Re-check in latest tool edition</button></div>  : null;

  return (
    <div className="row">
      <div className="col-md-12 rule-header clearfix">
        <h2 className="headline">{rule.title}&nbsp;<span className="rule-desc">{rule.desc}</span>&nbsp;
          <span className="rule-date">{`Edition ${convertDate(edition.createdAt)}`}</span>&nbsp;{updateEdition}
          {currentProject &&
          <div className="project-info">
            <span className="name">{currentProject.name}</span>
            <span className="date">{convertDate(currentProject.updatedAt)}</span>
            {updateAvailable}
          </div>}
        </h2>
        <RuleSubMenu 
          onSaveCurrentInput={onSaveCurrentInput} 
          onSaveNewProject={onSaveNewProject} 
          onDownloadInput={onDownloadInput}
          onDownloadExcel={onDownloadExcel} 
          onInportData={onInportData}
          onGenerateReport={onGenerateReport}
          currentProject={currentProject}
          onNewProject={onNewProject} />
      </div>
    </div>
  )
}

export default RuleHeader