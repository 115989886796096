import types from './types'
import api from '../../services/api'

export const fetchProjectListByRuleId = (ruleId) => {
  return dispatch => {
    return api
      .project
      .getByRuleId(ruleId)
      .then(data => dispatch(setProjectList(data.data)))
  }
}

const setProjectList = (projectList) => ({
  type: types.SET_PROJECT_LIST,
  projectList: projectList
})