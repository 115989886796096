import './scss/main.scss'
import React from 'react';
import ReactDOM from 'react-dom/client';
//import App from './App';
import App from './front/components/App'
import reportWebVitals from './reportWebVitals';
import {createStore, applyMiddleware } from 'redux'
import {Provider} from 'react-redux'
import { rootReducer } from './front/reducers';
import {init} from './front/actions'
import thunk from 'redux-thunk';

const store = createStore(rootReducer, (applyMiddleware(thunk)))
store.dispatch(init())
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}><App /></Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
