import React from 'react'
import {connect} from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import PreviewEdition from '../PreviewEdition'

class Preview extends React.Component {
  constructor(props) {
    super(props) 
  }

  render() {
    const {match, user} = this.props;
    if(!user.isSuperUser)
      return <p>access denied</p>;

    return (<Router>
      <Switch>
        <Route path={match.url + '/:ruleSlug/:editionId'} element={PreviewEdition} />
      </Switch>
    </Router>)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

export default connect(
  mapStateToProps,
)(Preview)
