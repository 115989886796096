import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Button, FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'
import Panel from 'react-bootstrap/lib/Panel'
import { ItemNumber, ItemDropDown, ItemText, ItemTable } from '../../../services/Item';
import {showHelpModal} from '../../actions/modal'
import {getImgUrl} from '../../../common'
import InlineMath from '../InlineMath'
require('./index.scss')
require("katex/dist/katex.css");

const classNames = require('classnames')

class RuleBlock extends Component {
  constructor(props) {
    super(props)
    
    this.onValueChanged = this.onValueChanged.bind(this)
    this.onAddPanelClicked = this.onAddPanelClicked.bind(this)
    this.onDeletePanelClicked = this.onDeletePanelClicked.bind(this)
    this.onHelpClicked = this.onHelpClicked.bind(this)
    this.onSaveBlockClicked = this.onSaveBlockClicked.bind(this)
  }

  onValueChanged(name, blockNo, val) {
    let { extStateStore, tree, block, rebuild } = this.props;
    extStateStore.setValue(name, blockNo, val)

    rebuild()
  }

  onAddPanelClicked(blockName) {
    const { extStateStore, rebuild } = this.props;
    extStateStore.appendBlock(blockName)
    rebuild();
  }

  onDeletePanelClicked(blockName, panelNo) {
    const { extStateStore, rebuild } = this.props;
    extStateStore.removeBlock(blockName, panelNo)

    rebuild();
  }

  onSaveBlockClicked(e) {
    
    // const {extStateStore, block, saveBlockClicked} = this.props;
    // const fullState = extStateStore.getFullState();
    const {saveBlockClicked} = this.props;
    saveBlockClicked();
  }

  getKatextSymbol(val) {
     return (val != undefined && val != '') ?
       <InlineMath math={val} /> : '';
  }

  onHelpClicked(e, item) {
    e.preventDefault();
    this.props.showHelpModal(item.help);
  }
  
  render() {
    let ii = '0' //check it
    let { extStateStore, tree, block } = this.props;
    let blockNo = extStateStore.getBlockNo(block.name);
    let panels = [];

    //let multiple = block.multiple;

    for (let currentBlockNo = 0; currentBlockNo < blockNo; currentBlockNo++) {
      let items = [];
      for (let itemNo = 0; itemNo < block.items.length; itemNo++) {

        const item = block.items[itemNo];
        const value = extStateStore.get(item.name, currentBlockNo)
        const error = (tree[item.name][currentBlockNo] && tree[item.name][currentBlockNo].error)
        const options = item.type === 'input' && item.ui.type === 'dropdown' ? tree[item.name][currentBlockNo].options : null;

        // If the item has a show method we should use ie
        if (!tree[item.name][currentBlockNo].show) {
          continue
        }

        // help button
        var help = '';
        if (item.help != undefined) {
          item.help.desc = item.desc
          help = <div className="infoLink" onClick={(e) => this.onHelpClicked(e, item)}>?</div>
        }

        //input
        if (item.type == "input") {
          items.push((
            <div key={itemNo + "_"} className="itembox">
              <div className="col-md-2 katext-placeholder">{this.getKatextSymbol(item.var)}</div>
              <div className="col-md-10 val-placeholder">
                {createItem(item, itemNo, currentBlockNo, this.props.staticpath, value, this.onValueChanged, error, options)}
              </div>
              {help}
            </div>))
        } else if (item.type == "figure") {
          items.push(createItem(item, itemNo, currentBlockNo, this.props.staticpath, value, this.onValueChanged, error, options))
        } else if (item.type == "heading") {
          items.push(
            <div key={item.name} className="itemboxfigure">
              <h2 style={{ borderBottom: "1px solid #ccc" }}>{item.desc}</h2>
            </div>)
        } else if (item.type == "output") {
          items.push(
            <div key={itemNo + "_"} className="itembox">
              <div className="col-md-2 katext-placeholder">
                {this.getKatextSymbol(item.var)}
              </div>
              <div className="col-md-10 val-placeholder">
                <FormGroup key={itemNo}>
                  <ControlLabel>{item.desc}</ControlLabel>
                  <FormControl readOnly type="text" value={tree.get(item.name, currentBlockNo)} />
                </FormGroup>
              </div>
              {help}
            </div>)
        }
      }

      //panels
      panels.push(
        <Panel key={`panel_${currentBlockNo}`}
          className={classNames({ 'panel-bordered': block.multiple })}
          {...(block.multiple && {header: (
                                <div className="clearfix">
                                  <h4 className="pull-left">{`${block.title} ${currentBlockNo + 1}`}</h4>
                                  {currentBlockNo > 0 &&
                                  <button type="button" 
                                    className="btn btn-default btn-md btn-default-plain pull-right"
                                    onClick={(e) => {e.preventDefault(); this.onDeletePanelClicked(block.name, currentBlockNo);}}
                                    >
                                    <span className="glyphicon glyphicon-minus circle" aria-hidden="true"></span> Delete</button>}
                                </div>)})}
        >
          {items}
        </Panel>
      )

      //add new panel button
      if (block.multiple && currentBlockNo == blockNo - 1) {
        panels.push(
          <button key={`addbutton_${currentBlockNo}`} 
                  type="button" 
                  className="btn btn-default btn-md btn-default-plain"
                  onClick={(e) => {e.preventDefault(); this.onAddPanelClicked(block.name)}}>
            <span className="glyphicon glyphicon-plus circle" aria-hidden="true"></span> Add {block.title}
          </button>
        )
      }
    }

    return (
      <div className="rule-block">
        <h3>{block.title}</h3>
        {panels}

        <div className="block-save">
          <button className="btn btn-primary" onClick={this.onSaveBlockClicked}>Save</button>
        </div>
      </div>)
  }
}


function createItem(item, itemNo, blockNo, staticpath, value, onValueChanged, error, options) {

  if (item.type === "input") {
    let type = item.ui;
    if (typeof type !== "string") {
      type = item.ui.type;
    }

    // Each type of input may have several default properties into which
    // we will merge the defined properties if it was an object.
    switch (type) {
      case "number":
        {
          let prop = {
            min: -Number.MAX_VALUE,
            max: Number.MAX_VALUE,
          }
          if (typeof item.ui === "object") {
            Object.assign(prop, item.ui)
          }
          return (
            <ItemNumber
              key={itemNo}
              error={error} 
              value={value} //value={StateStore.get(name,bi)}
              desc={item.desc}
              name={item.name}
              onValueChanged={(obj) => onValueChanged(item.name, blockNo, obj)}
            />
          )
          break;
        }
      case "text":
        {
          let prop = {}
          if (typeof item.ui === "object") {
            prop = Object.assign(prop, item.ui)
          }
          return (
            <ItemText
              key={itemNo}
              error={error} 
              value={value}   //value={extStateStore.get(name, blockNo)}       //value={StateStore.get(name,bi)}
              desc={item.desc}
              name={item.name}
              onValueChanged={(obj) => onValueChanged(item.name, blockNo, obj)}
            />
          )
          break;
        }
      case "dropdown":
        {

          let prop = Object.assign({}, item.ui);
          // let prop = {};
          // extend(prop, item.ui)

          return (
            <ItemDropDown
              key={itemNo}
              error={error} 
              value={value}       //value={extStateStore.get(name, blockNo)} //value={StateStore.get(name,bi)}
              desc={item.desc}
              name={item.name}
              options={options} //options={tree[item.name][blockNo].options}
              onValueChanged={(obj) => onValueChanged(item.name, blockNo, obj)}
            />
          )
          break;
        }
      case "table":
        {
          let prop = Object.assign({}, item.ui);
          // let prop = {};
          // extend(prop, item.ui)

          return (
            <ItemTable
              key={itemNo}
              error={error} 
              value={value}   //value={extStateStore.get(name, blockNo)} //value={StateStore.get(name,bi)}
              block={blockNo}
              desc={item.desc}
              name={item.name}
              {...prop}
              onValueChanged={(obj) => onValueChanged(item.name, blockNo, obj)}
            />
          )
          break;
        }
      default:
        throw "unknown input type in code"
    }
  } else if (item.type == "figure") {
    return (<div key={item.name} className="itemboxfigure ">
      <img className="sub img-desc" src={getImgUrl(item.src)} />
      <span className="sub" >{item.desc}</span>
    </div>)
  }
}

export default connect(null, {showHelpModal})(RuleBlock)