import './index.scss'
import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link} from 'react-router-dom'

import AdminPanel from '../../../back/components/App/'

const Header2 = ({user}) => {
  return (<header className="header header-dnvgl pass-header">
    <div className="container space-stack">
      <div className="pull-left branding">
        <a href="https://services.veracity.com/" className="logo logo-responsive">
          <img className="logo-image" src="/static/imgs/DNV_logo_RGB.png" />
        </a>
      </div>
      <div className="navbar-header pull-right">
        <Link className="site-title pass-logo hidden-xs" to="/">PASS Pre-Approval Screening Service</Link>
      </div>
    </div>
    <nav className="navbar navbar-dnvgl temporary">
      <div className="container">
        <div id="navbar" className="navbar-collapse collapse" aria-expanded="false">
          <div className="navbar-profile navbar-profile-offset-md">
            <div href="#" className="dropdown-toggle notification-item" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
               {user.name} <span className="caret"></span>
            </div>
            <div className="dropdown-menu log-out-menu">
              <ul>
                <li><a href="/account/signout/">Logout</a></li>
                {user.isSuperUser &&
                <Link to="/admin/">Admin panel</Link>
                }
              </ul>
              </div>
          </div>
        </div>
      </div>
    </nav>
  </header>)
}

export default Header2