import React, { Component } from 'react'
import './index.scss'
const classNames = require('classnames')


class RuleNav extends Component {
  constructor(props) {
    super(props)

    this.onNavClick = this.onNavClick.bind(this)
  }

  onNavClick(e, id) {
    e.preventDefault();
    this.props.selectNav(id)
  }

  render() {
    let { program, activeNav, tree } = this.props;

    const rectangle = <div className="dreieck" >
      <div className="dreieck_excl">!</div>
      <div className="dreieck_inner"></div>
      <div className="dreieck_border"></div>
      <span className="missingdata">Missing data</span>
    </div>

    const missingData2 = <small className="missing-data2">(Missing or malformatted data)</small>;
    if (!program)
      return null;

    activeNav = !!activeNav ? activeNav : 'intro';

    let blocksNames = [];
    program.iterBlocks((block, i) => {
      if(tree[block.name].show) {
        let error = tree.inputerrors.indexOf(block.name)>= 0;
        blocksNames.push({ id: block.name, name: block.title, blockIdx: i, error })
      }
    });
  
    let navItems = [{
      id: "intro",
      name: "Introduction"
    },
    ...blocksNames
      , {
      id: "results",
      name: "Results"
    }, {
      id: "submission",
      name: "Submission"
    }];

    return (<div className="col-md-3 rule-nav">
      <ul className="nav nav-stacked rule-nav-list">
        {navItems.map((ni, idx) => (
          <li key={ni.id} role="presentation" 
            className={classNames({ active: activeNav == ni.id}, "rule-nav-item")}>
            
            {/* <span className="glyphicon glyphicon-warning-sign missing-data" aria-hidden="true"> missing data</span> */}
              <a href="#" onClick={(e) => this.onNavClick(e, ni.id)} className={classNames({ active: activeNav == ni.id})} >{`${idx + 1}. ${ni.name}`}
              {/* {ni.error &&
                rectangle
              } */}

              {ni.error &&
                missingData2
              }
              </a>
          </li>))}
      </ul>
    </div>)
  }
}

export default RuleNav