import React from 'react'
import { programFactory } from '../../../services/rules2'
import RuleHeader from '../RuleHeader'
import RuleNav from '../RuleNav'
import RuleBody from '../RuleBody'
import ExtStateStore from '../../../services/ExtStateStore'
import alertify from 'alertify.js'
import { connect } from 'react-redux'
import { saveProject, setCurrentProject } from '../../actions/project'
import { fetchProjectListByRuleId } from '../../actions/projectList'
import { setCurrentRuleId } from '../../actions/rule'
import { updateCurrent, clearCurrent } from '../../actions/current'
import FileSaver from "file-saver"
import Excel from '../../../services/Excel'
import { loadExcelFile, loadJsonFile } from '../../../services/FileLoader'
import Report from '../../../services/Report'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'

class RuleContent extends React.Component {

	constructor(props) {
		super(props)

		this.loadProgram = this.loadProgram.bind(this)
		this.onProgramLoaded = this.onProgramLoaded.bind(this)
		this.selectNav = this.selectNav.bind(this)
		this.rebuild = this.rebuild.bind(this)
		this.onValueChangedOrAddDeletePanel = this.onValueChangedOrAddDeletePanel.bind(this)
		this.clearBlocker = this.clearBlocker.bind(this)
		// this.onBeforeUnload = this.onBeforeUnload.bind(this)
		this.changeEdition = this.changeEdition.bind(this)
		this.gotosub = this.gotosub.bind(this)
		//submenu
		this.onSaveCurrentInput = this.onSaveCurrentInput.bind(this)
		this.onSaveNewProject = this.onSaveNewProject.bind(this)
		this.onDownloadInput = this.onDownloadInput.bind(this)
		this.onDownloadExcel = this.onDownloadExcel.bind(this)
		this.onInportData = this.onInportData.bind(this)
		this.onGenerateReport = this.onGenerateReport.bind(this)

		this.updateToDefaultClicked = this.updateToDefaultClicked.bind(this)

		this.saveBlockClicked = this.saveBlockClicked.bind(this)
		this.getOutputState = this.getOutputState.bind(this)

		// New Project button
		this.onNewProject = this.onNewProject.bind(this);

		this.state = {
			activeNav: 'intro',
			tree: null,
			errors: null,
			warnings: null,
			calculationError: null,
			isFatching: false
			//valueChanged: false
		}
	}

	updateToDefaultClicked() {
		let { currentRule} = this.props;
		let newEditionId = currentRule.defaultEditionId

		alertify.prompt("What project name should It be stored under?", (projectName) => {
			if (!projectName) {
				alertify.error("You must give a name to save")
			} else {

				const state = ExtStateStore.getFullState()
				const project = {
					name: projectName,
					editionId: newEditionId,
					state: JSON.stringify(state.state)
				}

				this.props.saveProject(project)
					.then((newProject) => {

						this.props.fetchProjectListByRuleId(currentRule.id).then(() => {
							this.changeEdition(newEditionId)
							this.props.setCurrentProject({ id: newProject.id })
							alertify.success("Saved as " + projectName)
							this.clearBlocker();
						})

					})
					.catch(data => {
						alertify.error("Can't save project")
						console.error(data)
					})
			}
		})

	}

	changeEdition(editionId) {
		this.props.updateCurrent({ editionId: editionId })
		this.loadProgram(this.props.currentRule, this.props.currentEdition)
	}

	onProgramLoaded(program) {
		this.setState({ isFatching: false })
		ExtStateStore.setProgram(program);

		this.clearBlocker();
		this.rebuild();
	}

	selectNav(id) {
		this.setState({
			activeNav: id
		})
	}

	gotosub() {
		this.setState({
			activeNav: 'submission'
		})
	}

	componentDidMount() {
		this.setState({ isFatching: true })
		const { currentRule, currentEdition } = this.props;
		this.loadProgram(currentRule, currentEdition);
		this.props.fetchProjectListByRuleId(currentRule.id)
	}


	loadProgram(rule, edition) {
		programFactory({})
			.create(rule, edition)
			.then(p => this.onProgramLoaded(p))
			.catch(i => {
				console.error(i)
				this.setState({ isFatching: false })
			})
	}

	componentWillUnmount() {
		this.clearBlocker();

		this.props.setCurrentProject({});
		this.props.setCurrentRuleId({});
		this.props.clearCurrent();
		ExtStateStore.restore()
	}

	componentWillMount() {
		//this.unblock = this.props.history.block("Are you sure that you want to leave? You may have unsaved data.")
	}

	clearBlocker() {
		if (this.unblock) {
			this.unblock();
			//window.removeEventListener("beforeunload", this.onBeforeUnload)

		}
	}

	onValueChangedOrAddDeletePanel() {
		//this.setState({valueChanged: true})

		let message = "Are you sure that you want to leave? You may have unsaved data.";

		//block react router hook
		if (!this.unblock) {
			this.unblock = this.props.history.block(message)

			//window.addEventListener("beforeunload", this.onBeforeUnload)
		}

		this.rebuild();
	}

	// onBeforeUnload(e) {
	//   var confirmationMessage = message;

	//   e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
	//   return confirmationMessage;              // Gecko, WebKit, Chrome <34
	// }

	rebuild() {
		try {
			const program = ExtStateStore.getProgram();
			const tree = program.collect(ExtStateStore.getState())
			const { errors, warnings } = program.run(tree)

			this.setState({
				tree,
				errors,
				warnings
			})
		} catch (e) {
			console.error(e);
			this.setState({
				calculationError: true
			})
		}
	}

	//submenu action hanlers
	onSaveCurrentInput() {
		let { currentRule, currentEdition, currentProject } = this.props;

		//const state = ExtStateStore.getState()
		const state = ExtStateStore.getFullState()
		const project = {
			id: currentProject.id,
			name: currentProject.name,
			editionId: currentEdition.id,
			state: JSON.stringify(state.state)
		}

		this.props.saveProject(project)
			.then((newProject) => {
				this.props.fetchProjectListByRuleId(currentRule.id).then(() => {
					this.props.setCurrentProject({ id: newProject.id })

					alertify.success("Project saved");
					this.clearBlocker();
				})

			})
			.catch(data => {
				alertify.error("Can't save project")
				console.error(data)
			})
	}

	onSaveNewProject() {
		let { currentRule, currentEdition } = this.props;

		alertify.prompt("What project name should It be stored under?", (projectName) => {
			if (!projectName) {
				alertify.error("You must give a name to save")
			} else {

				const state = ExtStateStore.getFullState()
				const project = {
					name: projectName,
					editionId: currentEdition.id,
					state: JSON.stringify(state.state)
				}

				this.props.saveProject(project)
					.then((newProject) => {

						this.props.fetchProjectListByRuleId(currentRule.id).then(() => {
							this.props.setCurrentProject({ id: newProject.id })
							alertify.success("Saved as " + projectName)
						})

						this.clearBlocker();

					})
					.catch(data => {
						alertify.error("Can't save project")
						console.error(data)
					})
			}
		})
	}



	saveBlockClicked() {
		const { currentProject } = this.props;
		if (currentProject)
			this.onSaveCurrentInput()
		else
			this.onSaveNewProject()
	}

	getOutputState() {
		const program = ExtStateStore.getProgram();
		const now = new Date();

		return {
			now,
			state: {
				app: program.name,               //arc
				title: program.title,            //title: program.src.title,       //"Pressure vessels, Pt.4 Ch.7 "
				edition: program.edition,        //edition: program.src.edition,   //"July, 2016"
				saved: now,
				content: ExtStateStore.getFullState().state
			},
			iface: program.src.app.interface
		}
	}

	onDownloadInput() {
		const { now, state } = this.getOutputState()
		const fileName = `${state.app} ${now.getFullYear()}-${now.getMonth()}${now.getDay()}.json`
		var blob = new Blob([JSON.stringify(state, null, "  ")], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
		FileSaver.saveAs(blob, fileName);
	}

	onDownloadExcel() {
		const { now, state, iface } = this.getOutputState()
		const fileName = `${state.app} ${now.getFullYear()}-${now.getMonth()}${now.getDay()}.xlsx`
		//const updatedState = Object.assign({}, state, {edition: state.edition.toString()})
		const blob = Excel.toExcel(state /*updatedState*/, iface)
		FileSaver.saveAs(blob, fileName);
	}

	onInportData(e) {
		let files = e.target.files;
		if (files === undefined || files.length < 1)
			return

		let file = files[0]
		let isExcel = file.name.match(/\.xlsx$/)
		let loadFile = isExcel ? loadExcelFile : loadJsonFile;

		loadFile(file)
			.then((data) => this.loadobject(data))
			.catch(error => {
				console.error(error)
				alertify.error(error)
			})

	}

	loadobject(o) {

		const program = ExtStateStore.getProgram()

		if ((o.app !== program.name)) {
			//"The saved file is not matching the tool - doing my best to load anyway"
			alertify.log("Can't load file. The saved file is not matching the tool")
		} else {
			this.props.setCurrentProject({})
			this.changeEdition(o.edition)
			ExtStateStore.restore(null, o.content)
			alertify.success("Data loaded from file")
		}
	}

	onGenerateReport() {
		const { user } = this.props;
		const program = ExtStateStore.getProgram();

		let output = {}
		const tree = program.collect(ExtStateStore.getState())
		const { errors, warnings} = program.run(tree, output)

		var report = new Report(user, output, program, tree, errors, warnings)
		const html = report.toString()

		//const wnd = window.open("", "Report", "menubar=yes,resizable=yes,scrollbars=yes,status=yes,toolbar=yes");
		const wnd = window.open("", "Report", "resizable=yes,scrollbars=yes");
		wnd.document.write(html);
	}

	onNewProject() {
		this.props.setCurrentProject({});
		this.props.clearCurrent();
		ExtStateStore.restore()
		this.setState({ activeNav: "intro" });
	}

	render() {
		let {
			activeNav,
			tree,
			errors,
			warnings,
			calculationError } = this.state
		const { currentRule, currentEdition, currentProject } = this.props;
		const program = ExtStateStore.getProgram();
		if (!tree)
			return (<div />)

		return (
			<div className="container rule-content">
				{calculationError ? (
					<div className="alert alert-danger">
						There was a calculation error. Can not continue.
					</div>
				) : (
					<div>
						<RuleHeader
							currentProject={currentProject}
							rule={currentRule}
							edition={currentEdition}
							onSaveCurrentInput={this.onSaveCurrentInput}
							onSaveNewProject={this.onSaveNewProject}
							onDownloadInput={this.onDownloadInput}
							onDownloadExcel={this.onDownloadExcel}
							onInportData={this.onInportData}
							onGenerateReport={this.onGenerateReport}
							updateToDefaultClicked={this.updateToDefaultClicked}
							onNewProject={this.onNewProject}
						/>
						<div className="row">
							<RuleNav program={program} selectNav={this.selectNav} activeNav={activeNav} tree={tree} />
							<RuleBody
								changeEdition={this.changeEdition}
								program={program}
								activeNav={activeNav}
								tree={tree}
								extStateStore={ExtStateStore}
								results={{ errors, warnings }}
								rebuild={this.onValueChangedOrAddDeletePanel}
								rule={currentRule}
								edition={currentEdition}
								gotosub={this.gotosub}
								saveBlockClicked={this.saveBlockClicked}
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}

const stateToProps = (state, props) => {
	let currentProject = null;
	if (!isEmpty(state.projectList) && !isEmpty(state.currentProject)) {
		currentProject = state.projectList[state.currentProject.id]
	}

	const { ruleSlug, editionId: editionIdFromUrl } = props.match.params;
	const currentRule = values(state.rules.byIds).find(rule => rule.slug === ruleSlug);
	//if preview mode
	//const editionIdFromUrl = undefined;
	const editionId = editionIdFromUrl || state.current.editionId || currentRule.defaultEditionId;
	const currentEdition = state.editions.byIds[editionId];

	return {
		currentProject,
		currentRule,
		currentEdition,
		user: state.user,
	}
}

export default connect(stateToProps, { setCurrentRuleId, saveProject, fetchProjectListByRuleId, setCurrentProject, updateCurrent, clearCurrent })(RuleContent)
