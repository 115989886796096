import './index.scss'
import { Link } from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'

class EditorHeadline extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {rule, edition, onSaveAsWorkingCopyClicked, onSaveAsNewVersionClicked} = this.props;
    if(!(rule && edition))
      return null;

    return (
      <div className="editor-headline clearfix">
        
        <div className="action-buttons pull-right" >
          <button className="btn btn-primary" onClick={onSaveAsWorkingCopyClicked}>Save as working copy</button>
          <button className="btn btn-primary" onClick={onSaveAsNewVersionClicked}>Save as new version</button>
        </div>
        <h3>{edition.title}&nbsp;<small>{rule.title}</small></h3>
        
      </div>)
  }
}


export default EditorHeadline
