import React from 'react'
import api from '../../../services/api'
import ExtStateStore from '../../../services/ExtStateStore'
require('./index.scss')

class RuleSubmission extends React.Component {
  constructor(props) {
    super(props)  
  
    this.onSubmit = this.onSubmit.bind(this)
    
    this.state = {
      isFetching: false,
      calcResultId: null,
      error: ''
    }
  }

  componentWillUnmount() {
    this.setState({isFetching: false, calcResultId: null, error: ''})
  }

  onSubmit() {
    
    let { edition } = this.props;
    
    //const state = ExtStateStore.getState()
    const state = ExtStateStore.getFullState()
    const calculation = {
      editionId: edition.id,//ruleInfo.editionId,
      state: JSON.stringify(state.state)
    }
    
    this.setState({isFetching: true})
    api.calculation.add(calculation)
      .then(data => {
        if(data.success) 
          this.setState({calcResultId: data.calcResultId, isFetching: false})
        else {
          this.setState({error: 'Can not save results', isFetching: false})
        }
      })
      .catch(() => {
        this.setState({error: 'Can not save results', isFetching: false})
      })
  }

  render() {
    const {calcResultId, error, isFetching} = this.state;
    const errorContent = error ? (<div className="alert alert-danger">{error}</div>) : null;
    const content = !!calcResultId ? 
      <div className="alert alert-success">
        {`Thank you for using the PASS tool. A member of the PASS test team will contact you shortly. Your reference number is ${calcResultId}.`}
      </div> :
        <div>
          {errorContent}
          <p>
          Thank you for using the PASS tool. You may send your calculations to us to be used as part of an ongoing approval process by clicking the button below.
          </p>

          <div className="text-center">
            <button type="button" className="btn btn-primary" onClick={this.onSubmit} disabled={isFetching ? true : false} >Send calculations to PASS test team</button>
          </div>
        </div>;

    return (<div className="rule-submission">{content}</div>)
  }
}

export default RuleSubmission
