import api from '../services/api'
import types from './types'
import {setConfig as setConfiguration} from '../../common'

// Fetching data flag
export const fetchingData = () => ({
  type: types.ADMIN_FETCHING_DATA_REQUEST
})

export const fetchedData = () => ({
  type: types.ADMIN_FETCHING_DATA_COMPLETED
})

const setConfig = (config) => {
  setConfiguration(config)
  
  const tmp = {
    type: types.ADMIN_SET_CONFIG,
    config
  }
  return tmp;
}

const setUser = (user) => {
  return {
    type: types.ADMIN_SET_USER,
    user
  }
}

export const init = () => {
  return (dispatch) => {
    return api.init()
            .then(data => {
              dispatch(setConfig(data.adminConfig))
              dispatch(setUser(data.user))
            })
  }
}

export const setViewPort = (viewport) => {
  const containerHeight = viewport.height - 52;
  const menuHeight = containerHeight - 40;
  const editorHeight = Math.floor(containerHeight * 0.7);
  const editorLogHeigh = containerHeight - editorHeight - 98;
  return {
    type: types.SET_VIEWPORT,
    viewport: Object.assign(viewport, {containerHeight, menuHeight, editorHeight, editorLogHeigh})
  }
} 
