// require('es6-promise').polyfill();
// require('isomorphic-fetch');

const defaultConfig = {
  method: 'GET',
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":"*"
  },
  credentials: "same-origin",
}

const restClient = {
  get(url, conf) {
    const fetchConf = Object.assign({}, defaultConfig, conf)
    return new Promise((resolve, reject) => {
      fetch(url, fetchConf)
        .then(data => {
          if(data.status === 401) {
            window.location = '/account/signin';
            reject(data)
          }

          resolve(data);

        })
        .catch(err => {
          
        })
    }) 
    
  },

  post(url, body, conf) {
    let postRequiredConf = { method: 'POST' }
    if (body)
      postRequiredConf = Object.assign(postRequiredConf, { body: JSON.stringify(body) })

    const fetchConf = Object.assign({}, defaultConfig, conf, postRequiredConf)
    return fetch(url, fetchConf)
  },

  sendForm(url, form) {
    return fetch(url, Object.assign({}, defaultConfig, { body: form, method: 'POST' , headers: {} }))
  }
}

export default restClient;