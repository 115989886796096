import getParameterNames from 'get-parameter-names'
import {Items} from '../Item.js'

// Reserved javascript keywords which we do not allow to be used as names
const reserved = ["abstract",	"else",	"instanceof",	"super", "boolean",	"enum",
  "int", "switch", "break", "export", "interface", "synchronized", "byte",
  "extends", "let", "this", "case", "false", "long", "throw", "catch", "final",
  "native", "throws", "char", "finally", "new", "transient", "class", "float",
  "null", "true", "const", "for", "package", "try", "continue", "function",
  "private", "typeof", "debugger", "goto", "protected", "var", "default", "if",
  "public", "void", "delete", "implements", "return", "volatile", "do", "import",
  "short", "while", "double", "in", "static", "with",
  // Specific object properties we want to be able to use
  "block", "blocks", "item", "items", "inputerror", "inputerrors",
  // Keywords that we use and thus reserve
  "error", "retobj", "set", "get", "disp", "warn", "pe", "fi"].sort();

// A program is created from a source - note that the source is a javascript
// object which may cotnain functions as properties. These functions may be
// closing over other variables we do not knwo about - so we cannot close the
// source but will be making modifications to the original.
// After sending a src object into the constructor of a program you may not modify
// it externaly - or depend on it being identical to what it was before you sent
// it in (it will be e.g. normalied)
export default class Program {
  constructor(src) {
    if(src == undefined)
      throw "You must provide a source when creating a program"
    this.src = src;
    this.normalizeSrc()
    this.checkNames()
    this.buildBack()
    this.buildRules()
  }

  // Iterate f over all blocks, the send object may be modified
  iterBlocks(f) {
    for(let i = 0; i < this.src.app.interface.blocks.length; i++)
      f(this.src.app.interface.blocks[i], i)
  }

  // Iterate f over all items, the sent object may be modified
  iterItems(f) {
    this.iterBlocks((b, i) => {
      for(let j = 0; j < b.items.length; j++)
        if(b.items[j]) {  //added by tr
          f(b.items[j], j, i)
        }
    })
  }

  // For now does the following normalizations of the source:
  // 1. Replace the item type shorthand by the full shorthand
  // 2. Adds a show method to all inputs if not defined
  // 3. Adds a default value for each item if not defined
  // 4. Adds a show methods for all blocks if not defined
  // 5. Checks such that tables have columns defined and in order
  // 6. Checks outputs such that they have a value function
  normalizeSrc(src) {
    let alwaysShow = () => true
    this.iterItems((it) => {
      if(it.show == undefined)
        it.show = alwaysShow
      switch(it.type) {
      case "figure": {
        if(it.desc == undefined)
          throw(it.name + ": all figures must have a description")
        if(it.src == undefined || it.src == "")
          throw(it.name + ": all figures must have a src")
        return
      }
      case "heading": {
        if(it.desc == undefined)
          throw(it.name + ": all headings must have a description")
        return
      }
      case "input": {
        // Below handle the most common type of items - inputs
        if(it.ui.type == undefined) {
          let type = it.ui
          it.ui = {
            type: type,
          }
        }
        if(it.default == undefined) {
          switch (it.ui.type) {
            case "text":
              it.default = ""
            break;
            case "number":
              it.default = 0.0
            break;
            default:
              it.default = undefined
          }
        }
        if(it.ui.type=="table") {
          if(it.ui.columns == undefined || it.ui.columns.length <= 0)
            throw (it.name + ": all table inputs must specify the columns it consists of")
          it.ui.columns.map(c => {
            if(c.name == undefined || c.title == undefined)
              throw (it.name + ": all table columns must specify a name and a title")
          })
        }
        return
      }
      case "output": {
        if(it.ui.type == undefined) {
          let type = it.ui
          it.ui = {
            type: type,
          }
        }
        if(it.ui.type != "text")
          throw (it.name + ": unknown type of output")
        if(it.value == undefined || typeof it.value !== "function")
          throw (it.name + ": an output must have a value specified as a function")
        return
      }
      default:
        throw(it.name + ": unknow type '" + it.type + "'")
    }
    })
    this.iterBlocks(b => {
      if(b.show == undefined)
        b.show = alwaysShow
    })
    return src

  }

  // Do basic sanity check of the names in the src, it checks such that:
  // 1. All blocks have a name
  // 2. All items have a name
  // 3. All names are unique
  // 4. None of the reserved names are being used
  checkNames() {
    let names = {};
    let check = (n) => {
      if(n === undefined) {
        throw ("all items and blocks must have names defined")
      }
      if(names[n]!==undefined) {
        throw ("all items and blocks must have unique names, '" + n + "' is used more than once")
      }
      if(!this.allowedName(n)) {
        throw ("'" + n + "' is not allowed as a name for an item or block, it is reserved or contains illegal characters")
      }
      names[n] = true
    }
    this.iterBlocks(b => check(b.name))
    this.iterItems(i => check(i.name))
    return names;
  }

  // Checks if the given name is allowed as name or not
  allowedName(name) {
    if(reserved.indexOf(name)>= 0)
      return false
    return (/^[a-zA-Z0-9_]+$/i).test(name)
  }

  // Build the back structure allowing us to move from an name to a
  // description of that item/block etc.
  buildBack() {
    let back = {};
    this.iterBlocks((b, i) => back[b.name] = {type: "block", multiple: b.multiple, number: i, title: b.title, children: []})
    this.iterItems((it, j, i) => {
      if(it.type != "input")
        return
      // Add tiis as a children to the block
      let n = this.src.app.interface.blocks[i].name
      back[n].children.push(it.name)
      back[it.name] = {
        type: "item",
        parent: n,
        number: it.ui.type == "number",
        src: this.src.app.interface.blocks[i].items[j],
        default: this.src.app.interface.blocks[i].items[j].default
      };
    })
    this.back = back
  }


  // Build the rule functions and also do sanity checks of the rules, for now
  // does the following checks of the rules:
  // 1. Checks such that if loop is defined is is giving a name to a block that
  //    allows for multiple realizations
  buildRules() {
    let rules = [];
    const args = ["error", "disp", "get", "set", "warn"];
    for(let i = 0; i < this.src.app.rules.length; i++) {
        let r = this.src.app.rules[i];
        // Check that if a loop is defined it is valid
        if(r.loop != undefined) {
          if(this.back[r.loop] == undefined || this.back[r.loop].type != "block") {
            throw "rule " + i + " ("+r.ref+") loops over something that is not a block"
          }
        }
        // Create the function. We parse the generlated function to find the arguments
        // that the user has specified such that we can gather them togeather in an
        // outside function and thus not force the user to write all the boilerplate
        // var variable = get("variable")
        let vars = this.getVariables(r.rule)
        let ff = function() {
          let va = [];
          for(let i = 0; i < vars.length; i++) {
            try {
              va.push(this.get(vars[i]))
            } catch(e)  {
              console.warn(e);
              throw "Trying to access a variable that is not defined '" + vars[i] + "'"
            }
          }
          r.rule.apply(this, va)
        }
        ff.loop = r.loop
        rules.push(ff)
    }
    this.rules = rules
  }

  // From a function parse out the names of the arguments
  // and return them as an array by converting the function to a string.
  getVariables(ff) {
    return getParameterNames(ff);
    // let fs = ff + ""
    // let fu = (/\w+\((.*)\)/).exec(fs)[1]

    // fu = fu.split(",")
    // let vars = [];
    // for(let i = 0; i < fu.length; i++) {
    //   fu[i] = fu[i].trim()
    //   if(fu[i]=="")
    //     continue
    //   vars.push(fu[i])
    // }
    // return vars
  }

  // Runs through the input state and code to generate the dynamic block
  // list of all items that should be shown as well as their values based
  // on potential dynamic properties of other, previous, items. This must
  // be run before run and used as input since the rules require the actual
  // inputs as input.
  collect(state) {
    let getBlock = (name) => {
      return state.blocks[name] != undefined ? state.blocks[name].no : 1
    }

    let get = function(name, i) {
        if(state.data[name] == undefined) {
          return undefined
        }
        if(i == undefined) {
          let v = state.data[name]
          if(v.length == 1) {
            return v[0]
          }
          return v
        }
        let o = state.data[name][i]
        return o
    }
    // The tree object we will return with a get methods for easy use
    let tree = {};
    // Store if there was a local input error on this part
    tree.inputerrors = []
    tree.get = (name, i) => {
      if(tree[name] == undefined)
        return undefined
      if(i==undefined) {
        let b = []
        for(let j = 0; j < tree[name].length; j++)
          b.push(tree[name][j].value)
        return b
      }
      return tree[name][i] == undefined ? undefined : tree[name][i].value
    }

    let blocks = this.src.app.interface.blocks;
    for(let i = 0; i < blocks.length; i++) {
      let block = blocks[i]
      // We need to check the show method of the block before going through
      // the items i nthat block - it should not be allowed to depend on it's
      // own items.
      tree[block.name] = {
        isblock: true,
        no: getBlock(block.name),
        show: block.show((n) => {
          if(tree[n] == undefined || tree[n].isblock)
            throw "Trying to use a variable '" + n + "' in the show method for block'" + block.name + "' but it is not yet defined"
          return tree.get(n)
        })
      }
      if(!(tree[block.name].show)) {
        continue
      }
      for(let j = 0; j < block.items.length; j++) {
        let item = blocks[i].items[j]
        // Each instance of a block is saved in the corresponding index.
        let arr = [];
        tree[item.name] = arr
        arr.block = block.name;
        for(let k = 0; k < getBlock(block.name); k++) {
            arr[k] = {
              show: true,
              value: undefined,
            }
            // Run the show method to figure out if it should be shown or not
            // If it should not be shown we will not calculate the value since we
            // do not want any rules to be able to use it.
            arr[k].show = item.show((n) => {
              if(tree[n] == undefined || tree[n].isblock)
                throw "Trying to use a variable '" + n + "' in the show method for '" + item.name + "' but it is not yet defined"
              if(tree[n].block == block.name)
                return tree[n][k].value
              return tree.get(n)
            })
            if(!arr[k].show)
              continue
            if(item.type == "output") {
              arr[k].value = item.value((n) => {
                if(tree[n] == undefined || tree[n].isblock)
                  throw "Trying to use a variable '" + n + "' in the value method for output'" + item.name + "' but it is not yet defined"
                return tree.get(n, k)
              })
              continue
            }
            // For figures we also have to evaluate the src since this may be dynamic
            if(item.type == "figure") {
              if(typeof item.srcf === "function") {
                item.src = item.srcf((n) => {
                  if(tree[n] == undefined || tree[n].isblock)
                    throw "Trying to use a variable '" + n + "' in the src method for '" + item.name + "' but it is not yet defined"
                  if(tree[n].block == block.name)
                    return tree[n][k].value
                  return tree.get(n).length == 1 ? tree.get(n)[0] : tree.get(n)
                })
              }
            }
            if(item.type != "input")
              continue
            // If it is a dropdown we allow it's content to be dynamically
            // genereated - so do this.
            let src  = item
            if(typeof item.ui.options === "function") {
              src = {ui: {}};
              src.ui.options = item.ui.options((n)  => {
                if(tree[n] == undefined || tree[n].isblock)
                  throw "Trying to use a variable " + n + " in a show method that is not yet defined"
                if(tree[n].block == block.name)
                  return tree[n][k].value
                return tree.get(n)
              })
              arr[k].options = src.ui.options
            } else {
              arr[k].options = item.ui.options
            }
            // Calculate the value of this input based on its type
            let v = Items[item.ui.type].toValue(src, get(item.name,k))
            arr[k].value = v.value
            arr[k].error = v.error
            arr[k].input = v.input

            // Store that there was an error here
            if(v.error != undefined && v.error != "" && tree.inputerrors.indexOf(block.name)<0) {
              tree.inputerrors.push(block.name)
            }
        }
      }
    }
    return tree
  }

  // Run a rule evaluation for the input (which can be genereated using collect())
  // detailed output is generated in the output object.
  run(tree, output) {
    let evaluator = new Evaluator(this, tree, output);
    evaluator.collectInput()
    // Run all the rules one by one in the order defined in the program,
    // long term we may be able to only re-run tose that have changed, but the
    // bookkeeping is not worth it for simple and quick rules as this.
    for(let i = 0; i < this.rules.length; i++) {
      // So now we have the function, we need to the get the correct data
      // to send in as arguments, checking if we should loop over anything
      // for this one. If We are looping we need to be carefull and store also
      // the looped variables in a array, such that they may only be used in
      // an expression using the same loop.
      let r = this.src.app.rules[i];
      let rf = this.rules[i];
      // Find out how many invocation we should do of this rule
      let noInvokes = 1;
      if(r.loop != undefined)
        noInvokes = tree[r.loop].no
      // If the rule is defined with a loop over something which is not shown
      // the rule should not be run.
      if(r.loop != undefined && !tree[r.loop].show)
        continue
      for(let no = 0; no < noInvokes; no++) {
        evaluator.willRun(i, no, r.loop);
        let retobj = {};
        try {
          retobj =  rf.apply(evaluator.args,[])
        } catch (e) {
          console.error("rule " + i + " (" + r.ref + ")threw an exception while being run: " + r.ref + ", " + r.desc, e, e.stack)
          evaluator.internalError();
        }
      }
    }
    evaluator.buildOutputs();
    let errors = evaluator.getErrors();
    if(tree.inputerrors.length > 0)
      errors.unshift("There were input errors or missing input found")
    let warnings = evaluator.getWarnings();
    return {errors: errors, warnings: warnings}
  }
}

// TODO: Clean up and document this class. It is used to keep track of variables
// such that the code can never set the same twice, set one that is defined etc.
class Evaluator {
  constructor(program, tree, output) {
    this.src = program.src;
    this.back = program.back;
    this.output = output
    this.tree = tree;
    this.args = {
      error: this.error.bind(this),
      disp: this.disp.bind(this),
      get: this.get.bind(this),
      set: this.set.bind(this),
      warn: this.warn.bind(this)
    }
    // Keep track of which
    this.used = {}
    this.errors = []
    this.warnings = []
    this.variables = {};
    this.variableorder = []
    this.evalerror = false
    // Local messages and errors for each rule, only stored if we are in output mode
    this.lerrors = [];
    this.disps = [];
    this.lwarnings = [];
    // We need to define all the input variables as used since we cannpt use them
    for(let nm in this.back) {
      if(this.back.hasOwnProperty(nm)) {
        this.used[nm] = true
      }
    }
  }

  // Add userfacing error messages notifying that an internal error did occure
  internalError() {
    if(!this.evalerror) {
      this.evalerror = true
      this.errors.unshift("Internal calculation error encountered")
    }
    if(this.lerrors[this.ri] == undefined)
      this.lerrors[this.ri] = [];
    if(this.lerrors[this.ri][this.ii] == undefined)
      this.lerrors[this.ri][this.ii] = [];
    this.lerrors[this.ri][this.ii].push("Internal calculation error encountered")
  }

  // Set the evaluater into run state for the given rule and invocation
  willRun(ri, ii, loop) {
    this.loop = loop
    this.ri = ri
    this.ii = ii
  }

  // Get all the errors outputted thus far
  getErrors() {
    return this.errors
  }

  getWarnings() {
    return this.warnings
  }

  // Convenience method for replacing "$ref" in a error message with the rules ref
  replaceRef(text) {
    let ref = this.src.app.rules[this.ri].ref
    if(ref ==undefined)
      ref = ""
    return text.replace(/\$ref/g, ref)
  }

  // Called by user defined functions to let know of errors
  error(link, msg) {
    msg = this.replaceRef(msg)
    this.errors.push(
      (this.loop != undefined ?
        this.back[this.loop].title + " - " + (1+ this.ii) + ": " :
         "" )
      + msg)
    if(this.output == undefined)
      return
    if(this.lerrors[this.ri] == undefined)
      this.lerrors[this.ri] = []
    if(this.lerrors[this.ri][this.ii] == undefined)
      this.lerrors[this.ri][this.ii] = []
    this.lerrors[this.ri][this.ii].push(msg)
  }

  // Called by user defined functions to send output text to the report
  disp(msg) {
    if(this.output == undefined)
      return
    if(this.disps[this.ri] == undefined)
      this.disps[this.ri] = []
    if(this.disps[this.ri][this.ii] == undefined)
      this.disps[this.ri][this.ii] = []
    this.disps[this.ri][this.ii].push(msg)
  }

  // Called by user functions to create warnings (i.e. Fi comments)
  warn(link, msg) {
    msg = this.replaceRef(msg)
    this.warnings.push(
      (this.loop != undefined ?
        this.back[this.loop].title + " - " + (1+ this.ii) + ": " :
         "" )
      + msg)
    if(this.lwarnings[this.ri] == undefined)
      this.lwarnings[this.ri] = []
    if(this.lwarnings[this.ri][this.ii] == undefined)
      this.lwarnings[this.ri][this.ii] = []
    this.lwarnings[this.ri][this.ii].push(msg)
  }

  // create a temporary variable that can be used later, if we are in a loop we
  // should store the variable individually for each loop realization.
  set(name, val) {
    if(this.used[name]!=undefined && (this.loop == undefined || this.used[name]!=this.ri) )
      throw "rule " + this.ri + " tried to set " + name + " but it has been used or set earlier"
    if(this.output != undefined && this.used[name] == undefined)
      this.variableorder.push(name)
    this.used[name] = this.ri;
    if(this.loop == undefined) {
      this.variables[name] = [{value: val}]
      this.variables[name].ri = this.ri
      return
    }
    if(this.ii == 0 || this.variables[name] == undefined) {
      this.variables[name] = []
      this.variables[name].loop = this.loop
      this.variables[name].ri = this.ri
    }
    this.variables[name][this.ii] = {value: val}
  }l

  // Used by rules to return the value of the name, if we are in a loop we should return the
  // number we are in (if this name is from that block). We try to lookup the
  // value both amongst defined variables and global ones fom input
  get(name) {
    if(this.variables[name] == undefined && this.back[name] == undefined) {
      throw "Trying to access a variable that is not defined: " + name
    }

    this.used[name] = true;
    if(this.loop == undefined) {
      if(this.variables[name] != undefined)
        return this.tovalue(this.variables[name], name)
      return this.tovalue(this.tree[name], name)
    }
    // We are in a loop, either we are in the same block where the name was
    // defined or we are not
    if(this.variables[name] != undefined) {
      if(this.variables[name].loop == this.loop) {
        return this.variables[name][this.ii].value
      }
      return this.tovalue(this.variables[name], name)
    } else {
      if(this.back[name].parent != this.loop) {
        return this.tovalue(this.tree[name], name)
      }
      return this.tree[name][this.ii].value
    }
  }

  // Returns the value, or value array depending on if there is more than one
  // item in the array
  tovalue(arr, name) {
    //problem is that the created variables don't have a back'
    if(arr == undefined)
      return undefined
    if(!Array.isArray(arr))
      return arr.value
    if(arr.length == 1 &&
      (
        (this.back[name] != undefined && this.back[this.back[name].parent].multiple != true)
      ||
        (this.variables[name] != undefined && this.variables[name].loop == undefined)
      )
    ) {
      return arr[0].value
    }
    let values = [];
    for(let i = 0; i < arr.length; i++) {
      values.push(arr[i].value)
    }
    return values
  }


  // Collect all user specified input into the output object such that it
  // may later be displayed.
  collectInput() {
    if(this.output == undefined)
      return
    this.output.userinput = [];
    // Loop through the tree in order to extract the values
    // the user has inputted for each block.
    for(let i = 0; i < this.src.app.interface.blocks.length; i++) {
      let block = this.src.app.interface.blocks[i];
      // If the block is hidden we should not get it
      if(!this.tree[block.name].show)
        continue
      for(let j = 0; j < this.tree[(block.name)].no; j++) {
        let inp = {
          title :  block.title + ( this.tree[block.name].no > 1 ? " " + (j+1) : ""),
          items:  []
        };
        for(let k = 0; k < block.items.length; k++) {
          if(block.items[k].type != "input")
            continue
          let it = {
            desc: block.items[k].desc,
            name: block.items[k].name,
          }
          it.value = this.tree[it.name][j].value;
          it.input = this.tree[it.name][j].input;
          // Handle dropdowns specifically - let the value be the string from the
          // choice instead of the value!
          if(block.items[k].ui.type == "dropdown") {
            if(it.input == undefined)
              it.input = 0
            it.value = this.tree[it.name][j].options
            if(it.value != undefined && it.input < it.value.length )
              it.value = it.value[it.input].text
            else
              it.value = "-- NO OPTIONS --"
          }
          if(this.tree[it.name][j].show === false)
            continue
          inp.items.push(it)
        }
        this.output.userinput.push(inp)
      }
    }
  }

  buildOutputs() {
    if(this.output == undefined)
      return

    this.output.calculated = [];
    this.output.rulestatus = [];
    // Go through all the variables created and build the list of outputs
    // to set to the output so that the steps can be printed to the user
    for(let i = 0; i < this.variableorder.length; i++) {
      let vn = this.variableorder[i];
      let v = this.variables[vn]
      for(let j = 0; j < v.length; j++) {
        this.output.calculated.push({
          name: vn,
          ref: this.src.app.rules[v.ri].ref,
          assoc: v.length > 1 ? this.back[v.loop].title + " " + (j+1) : "",
          value: v[j] == undefined ? undefined : v[j].value,
        })
      }
    }

    // Produce the rule statuses for each of the rules that has been evaluated
    for(let i = 0; i < this.src.app.rules.length; i++) {
      let r = this.src.app.rules[i]
      let no = 1
      if(r.loop != undefined)
        no = this.tree[r.loop].no
      for(let j = 0; j < no; j++) {
        let obj = {
          ref: (r.ref != undefined) ? r.ref : "no name given for rule",
          assoc: no > 1 ? this.back[r.loop].title + " " + (j+1) : "",
          errors: this.lerrors[i] == undefined || this.lerrors[i][j] == undefined ? [] : this.lerrors[i][j],
          disps: this.disps[i] == undefined || this.disps[i][j] == undefined ? [] : this.disps[i][j],
          warnings: this.lwarnings[i] == undefined || this.lwarnings[i][j] == undefined ? [] : this.lwarnings[i][j],
        }
        this.output.rulestatus.push(obj)
      }
    }
  }
}
