import React from 'react'
import {fetchPreviewEdition} from '../../actions/rule'
import {updateCurrent} from '../../actions/current'
import {connect} from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch
} from 'react-router-dom'
import RuleContent from '../RuleContent'
import values from 'lodash/values'

class PreviewEdition extends React.Component {
  constructor(props) {
    super(props)  

  }

  componentDidMount() {
    const { editionId } = this.props.match.params;
    this.props.fetchPreviewEdition(editionId)
      .then((data) => {
        this.props.updateCurrent({editionId})
        //this.props.history.push(`/${data.rule.slug}`, {editionId})
      })
  }

  render() {

    const {match} = this.props;
    const {editionId, ruleSlug} = match.params;
    const {currentEdition, currentRule} = this.props
    if(!(currentRule && currentEdition))
       return null;

    return (<Router>
      <Switch>
        <Route  path={`/preview/:ruleSlug/:editionId`} element={RuleContent} /> 
      </Switch>
    </Router>)
  
  }
}


const stateToProps = (state, props) => {
  
  let currentEdition = undefined;
  let currentRule = undefined;
  const {editionId, ruleSlug} = props.match.params;
  
  if(!isEmpty(state.editions.byIds) && state.editions.byIds.hasOwnProperty(editionId))
    currentEdition = state.editions.byIds[editionId]
  
  if(!isEmpty(state.rules.byIds))
  {
    currentRule = values(state.rules.byIds).find(r => r.slug == ruleSlug)
  }

  return {
    currentEdition,
    currentRule
  }
}

export default connect(stateToProps, {fetchPreviewEdition, updateCurrent})(PreviewEdition)

