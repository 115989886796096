import api from '../services/api'
import types from './types'
import {fetchingData, fetchedData} from './index'

const setRules = (rules) => {
  
  
  return ({
    type: types.ADMIN_SET_RULES,
    payload: rules
})}

export const fetchRules = () => {
  return (dispatch, getState) => {
    dispatch(fetchingData())
    return api.getRulesInfo()
      .then(data => {
        dispatch(setRules(data.data))
        dispatch(fetchedData())
        return data.data;
      })
      .catch(() => {
        dispatch(fetchedData())
      })
  }
}

export const setCurrentRuleId = (ruleId) => ({
 type: types.SET_CURRENT_RULE_ID,
 currentRuleId: ruleId 
})



// export const fetchRuleById = (ruleId) => {
//   return (dispatch, getState) =>  {
//     const state =  getState();
//     const rule = state.get(['rules', ruleId.toString()]);
    
//     if(rule)
//       return;

//     return api
//       .rules
//       .fetchById(id)
//       .then(data => dispatch(setRule(data)))
//   }
// }
