import './index.scss'
import React from 'react'
import {marked} from 'marked'
import RuleSaves from '../RuleSaves'
import {getImgUrl} from '../../../common'


const RuleIntro = ({text, rule, edition, changeEdition}) => {
  
  let renderer = new marked.Renderer()

  renderer.image = (href, title, text) => `<img src="${getImgUrl(href)}" alt="${text}" >`
  let html = marked(text,{renderer: renderer})

  return (
    <div className="row">
      <div className="col-md-8 rule-text">
        <div className="docs rule-intro" dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>
      <div className="col-md-4 rule-projects">
        <RuleSaves rule={rule} edition={edition} changeEdition={changeEdition} />
      </div>
    </div>
  )
}

export default RuleIntro