// require('es6-promise').polyfill()
// require('isomorphic-fetch')

let config = {};

export const setConfig = (cfg) => {
  Object.assign(config, cfg)
}

export const getImgUrl = (fileName) => {
  return (`${config.blobUrl}/${fileName}`)
}