import './index.scss'
import { connect } from 'react-redux'
import React from 'react'
import RuleBox from '../RuleBox'

const HomeContent = (props) => {
  const {rules} = props;

  return (
    <div className="container home-content">
      <div className="row">
        <div className="col-md-12">

          <h2 className="headline">Welcome to the Pre-Approval Screening Service</h2>
          <p>Your online service for technical, pre-verification, evaluation of components. It provides early warnings about design parameters not fulfilling the relevant rules. By selecting your particular type of component you will have access to a purpose made tools tuned for your components.<br /><br />
          Feel free to us the pre-approval screening service without requesting formal approval - this allows you to catch potential problems early in the design cycle.</p>
          <h3 className="headline-small">Choose a rule</h3>

          {rules.map(r => (<RuleBox key={r.id} rule={r} routerHistory={props.history} />))}

        </div>
      </div>
    </div>)
}

const stateToProps = (state) => ({
  rules: state.rules.allIds.map(ruleId => state.rules.byIds[ruleId])
})

export default connect(stateToProps)(HomeContent)