export default {
  ADMIN_FETCHING_DATA_REQUEST: 'ADMIN_FETCHING_DATA_REQUEST',
  ADMIN_FETCHING_DATA_COMPLETED: 'ADMIN_FETCHING_DATA_COMPLETED',
  SET_CURRENT_RULE_ID: 'SET_CURRENT_RULE_ID',
  SET_CURRENT_EDITION_ID: 'SET_CURRENT_EDITION_ID',
  CHANGED_CODE_LOCAL: 'CHANGED_CODE_LOCAL',

  SET_IMAGES: 'SET_IMAGES',
  ADMIN_SET_CONFIG: 'ADMIN_SET_CONFIG',
  ADMIN_SET_USER: 'ADMIN_SET_USER',
  SET_VIEWPORT: 'SET_VIEWPORT',

  SET_EDITORLOG: 'SET_EDITORLOG',

  ADMIN_SET_RULES: 'ADMIN_SET_RULES',
  ADMIN_SET_EDITIONS: 'ADMIN_SET_EDITIONS',
  ADMIN_UPDATE_EDITION: 'ADMIN_UPDATE_EDITION',
  ADMIN_ADD_EDITION: 'ADMIN_ADD_EDITION',
}
