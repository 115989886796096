import './index.scss'
import React from 'react'
import {getImgUrl} from '../../../common' 
const RuleBox = ({routerHistory, rule}) => {

  return (
    <div className="rule_box">
      <img src={getImgUrl(rule.img)} alt="ruleImage" />
      <p className="rule-title">{rule.title}</p>
      <p className="rule-desc">{rule.desc}</p>
      <button type="button" className="btn btn-primary btn-sm use-rule" onClick={() => { routerHistory.push(`/${rule.slug}`) }}>Use rule</button>
    </div>
  )
}

export default RuleBox