
let cbs = [];
const callCbs = () => {
  cbs.forEach(c => c())
}

const internalState = {
  //private
  _data: {},   // Each input has a name which will be the property of this one (each one is an array)
  _blocks: {}, // Keeping track of how many blocks of each type there are, default 1
  _errors: {}, // List of input errors such that we know when there is an input error and not
  _program: {},

  appendBlockIfUndefined(blockName) {
    if(this._blocks[blockName] == undefined) {
      this.appendBlock(blockName)
    }
  },

  appendBlock(name) {
    if (this._blocks[name] == undefined)
      this._blocks[name] = { no: 0 }
    this._blocks[name].no++
  },

  removeBlock(name, i) {
    let no = this._blocks[name].no
    if (i < 0 || i >= no)
      console.error("Trying to remove a block that does not exist", name, i, no)
    // So removing does not mean that we remove the data from the stored state,
    // we simply move it to the end such that if you create it again the numbers
    // are remembered.
    // Note that we need to query the src of the app to know which inputs are
    // connected to this block and thus should be moved.
    this._blocks[name].no--
    let childs = this._program.back[name].children
    for (let j = 0; j < childs.length; j++) {
      let c = childs[j]
      if (this._data[c] != undefined) {
        if (this._data[c].length > i + 1) {
          let temp = this._data[c][i]
          this._data[c][i] = this._data[c][this._data[c].length - 1]
          this._data[c][this._data[c].length - 1] = temp
        }
      }
    }
  },

  restore(program, ns) {
    this._program = program
    if (ns != undefined) {
      this._blocks = ns.state.blocks;
      this._data = ns.state.data;
    } else {
      this._blocks = {}
      this._data = {}
    }
  },

  //public
  getValue(name, i) {
    if (this._data[name] == undefined) {
      return undefined
    }
    if (i == undefined) {
      let v = this._data[name]
      if (v.length == 1) {
        return v[0]
      }
      return v
    }
    let o = this._data[name][i]
    return o
  },

  setValue(name, i, value) {
    let block = this._program.back[name].parent
    this.appendBlockIfUndefined(block);
    
    let blockNo = this._blocks[block].no;


    if (i < 0 || i >= blockNo)
      console.error("Trying to set a value in a block number that is invalid", name, i, blockNo)

    if (this._data[name] == undefined)
      this._data[name] = [];
    for (; this._data[name].length <= i; this._data[name].push(undefined)) { }
    this._data[name][i] = value
    
    callCbs();
  },

  getBlockNo(name) {
    this.appendBlockIfUndefined(name);
    return this._blocks[name].no; 
  },

  getProgram() {
    return this._program;
  }
}

export default {
  get(name, i) {
    return internalState.getValue(name, i);
  },

  setValue(name, blockNo, value) {
    internalState.setValue(name, blockNo, value)
  },

  getState() {
    return {
      data: internalState._data,
      blocks: internalState._blocks,
      errors: internalState._errors,
      program: internalState._program
    }
  },

  getBlockNo(blockName) {
    
    return internalState.getBlockNo(blockName)
  },

  setProgram(program) {
    internalState._program = program;
  },

  getProgram() {
    return internalState._program;
  }, 

  onChange(cb) {
    cbs.push(cb);
  },

  removeBlock(blockName) {
    internalState.removeBlock(blockName)
  }, 

  appendBlock(blockName) {
    internalState.appendBlock(blockName)
  },

  getFullState() {
    const program = internalState.getProgram();
    let nice = {
      name: program.name,
      version: program.version,
      date: (new Date()).toISOString(),
      state:  {blocks: internalState._blocks, data: {}}
    }
   
    // Copy the data since we should only include the input
    // and not the values
    for(let nm in internalState._data) {
      if(internalState._data.hasOwnProperty(nm)) {
        nice.state.data[nm] = [];
        for(let i = 0; i < internalState._data[nm].length; i++) {
          if(internalState._data[nm][i] == undefined)
            continue
          nice.state.data[nm].push(internalState._data[nm][i])
        }
      }
    }
    return nice
  },

  restore(program, ns) {
    if(program)
      internalState._program = program
    if(ns != undefined) {
      internalState._blocks = ns.blocks;
      internalState._data = ns.data;
    } else {
      internalState._blocks = {}
      internalState._data = {}
    }
    return
  }
}
