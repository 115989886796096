import api from "../../services/api"
import types from './types'

export const saveProject = (project) => {
  return (dispatch) => {
    return api.project
      .save(project)
      .then((data) => {
        return data;
      })
      
  }
} 

export const setCurrentProject = (project) => ({
  type: types.SET_CURRENTPROJECT,
  project: project 
})