import './index.scss'
import React from 'react'
import {connect} from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import Editor from '../Editor'
import EditorImages from '../EditorImages'
import EditorHeadline from '../EditorHeadline'
import EditorLog from '../EditorLog'
import {saveWorkingCopy, 
  localCodeUpdate, 
  setCurrentEditionId, 
  createNewEdition,
  refreshById} from '../../actions/editions'
import alertify from 'alertify.js'

 class EditorArea extends React.Component {
  constructor(props) {
    super(props)
   
    this.handleSelect = this.handleSelect.bind(this)
    
    this.onSaveAsWorkingCopyClicked = this.onSaveAsWorkingCopyClicked.bind(this)
    this.onSaveAsNewVersionClicked = this.onSaveAsNewVersionClicked.bind(this)

    this.codeChanged = this.codeChanged.bind(this)

    this.state = { eventKey: 'editor' }
  }

  onSaveAsWorkingCopyClicked() {
    const { rule, edition, createWorkingCopy } = this.props;
    
    alertify.success("Saving working copy")
    this.props.saveWorkingCopy({
      ruleId: rule.id,
      code: edition.code
    })
    .then(newEdition => {
      
      this.props.setCurrentEditionId(newEdition.id)
      //refresh old edition
      this.props.refreshById(edition.id)
      
      alertify.success("Saved working copy");
    })
    .catch((data) => {
      console.error(data)
      alertify.error("Can't save working copy")
    })
  }

  onSaveAsNewVersionClicked() {
    const { rule, edition } = this.props;
    alertify.prompt("Please enter the version title", (title) => {
      if (!title) {
        alertify.error("You must give a version title to save it")
      } else {
        alertify.success("Saving new version")
        this.props.createNewEdition({
          title: title,
          ruleId: rule.id,
          code: edition.code
        })
        .then((newEdition) => {
          this.props.setCurrentEditionId(newEdition.id)
          //refresh old edition
          this.props.refreshById(edition.id)
          
          alertify.success(`New version "${newEdition.title}" saved`)  
        })
        .catch((data) => {
          console.error(data)
          alertify.error("Can't create a new version")
        })      
      }   
    })
  }

  codeChanged(code) {
    const {edition} = this.props;
    this.props.localCodeUpdate(edition.id, code)
  }

  handleSelect(key) {
    this.setState({ eventKey: key })
  }

  render() {
    let activeKey = this.state.eventKey;
    const { edition } = this.props;

    if(!edition)
      return null;
    
    return (
      

      <div className="editor-area">
        <EditorHeadline 
          onSaveAsWorkingCopyClicked={this.onSaveAsWorkingCopyClicked}
          onSaveAsNewVersionClicked={this.onSaveAsNewVersionClicked}
           {...this.props} />

        <Tabs activeKey={activeKey} onSelect={this.handleSelect} id="screen-switcher" className="editor-tabs" >
          <Tab eventKey='editor' title="Editor">
            <Editor codeChanged={this.codeChanged} {...this.props} />
            <EditorLog /> 
          </Tab>
          <Tab eventKey='images' title="Images">
            <EditorImages />
          </Tab>
        </Tabs>
      </div>)
  }
}

const stateToProps = (state) => {
  const currentRuleId =  state.adminCurrent.currentRuleId;
  const currentEditionId = state.adminCurrent.currentEditionId;
  let rule = currentRuleId && state.adminRules.has(currentRuleId.toString()) ? (state.adminRules.get(currentRuleId.toString()))/*.toJS()*/ : null;
  let edition = currentEditionId && state.adminEditions.has(currentEditionId.toString()) ? (state.adminEditions.get(currentEditionId.toString()))/*.toJS()*/ : null;
  return {
    rule,
    edition,
    viewport: state.viewport
  }
}

export default connect(stateToProps, {saveWorkingCopy, localCodeUpdate, setCurrentEditionId, createNewEdition, refreshById})(EditorArea)