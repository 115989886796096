import types, {modalTypes} from './types'

export const showHelpModal = (helpObj) => ({
  type: types.SHOW_MODAL,
  modalType: modalTypes.HELP_MODAL,
  modalProps: {
    ...helpObj
  }
})

export const hideHelpModal = () => ({
  type: types.HIDE_MODAL,
  modalType: modalTypes.HELP_MODAL,
  modalProps: {} 
})