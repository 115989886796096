import './index.scss'
import React from 'react'

const RuleResults = ({ results, gotosub }) => {
  let status = [];

  if (results.errors == undefined || results.length <= 0) {
    status.push(<button key={-2} type="button" className="btn btn-success" onClick={gotosub}>All checks OK, go to submission</button>)
  } else {
    status.push(<h5 key={-1}>The rule checks have resulted in the following warnings:</h5>);

    for (let i = 0; i < results.errors.length; i++) {
      let r = results.errors[i];
      let m = /(.* - \d+: )(.*)/.exec(r)
      let badge = null;
      if (m == null) {
        status.push(<div key={"e-" + i} className="result-item result-warning">
          <span className="result-text">{r}</span>
        </div>)
      } else {
        status.push(<div key={"e-" + i} className="result-item result-warning">
          <span className="block-name">{m[1].substring(0, m[1].length - 2)}</span>
          <span className="result-text">{m[2]}</span>
        </div>)
      }
    }
  }

  if (results.warnings) {
    for (let i = 0; i < results.warnings.length; i++) {
      let r = results.warnings[i];
      let m = /(.* - \d+: )(.*)/.exec(r)
      let badge = null;
      if (m == null) {
        status.push(<div key={"w-"+i} className="result-item result-note">
          <span className="result-text">{r}</span>
        </div>)
      } else {
        status.push(<div key={"w-"+i} className="result-item result-note">
          <span className="block-name">{m[1].substring(0, m[1].length - 2)}</span>
          <span className="result-text">{m[2]}</span>
        </div>)
      }
    }
  }

  return (
    <div className="rule-results">
      <h3>Results</h3>
      <p>
        The rule check results are presented below. Please note that, as further detailed in the introduction this should not be considered a complete rule check. These checks are designed to capture the most commonly issued comments and thus give predictability during the approved process, as well as decreased handling time.
    </p>
      {/* <h5>
    The rule checks have resulted in the following warnings:
    </h5> */}

      <div className="result-list">

        {status}

      </div>

    </div>
  )
}

export default RuleResults