// export const formatDate = (date) => {
//   if(!date)
//     return "";
//   let hours = date.getHours();
//   let minutes = date.getMinutes();
//   const ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? '0'+minutes : minutes;
//   const strTime = hours + ':' + minutes + ' ' + ampm;
//   return date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
// }

export const EditionTypeRegular = "Regular";
export const EditionTypeWC = "WorkingCopy";

export const EditionType =
{
    0: EditionTypeRegular,
    1: EditionTypeWC
}


