import omit from 'lodash/omit'
import types from '../actions/types'

export const current = (state = {}, action) => {
  switch(action.type) {
    case types.UPDATE_CURRENT: 
      return Object.assign({}, state, action.payload )
    case types.CLEAR_CURRENT:
      if(action.payload === 'all')
        return {}
      return omit(state, action.payload)
    default:
      return state;
  }  
} 