import './index.scss'
import React from 'react'
import {connect} from 'react-redux'
import MonacoEditor from 'react-monaco-editor'
import {setEditorLog} from '../../actions/editorlog' 


class Editor extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.editorDidMount = this.editorDidMount.bind(this)
    this.onValidationCompleted = this.onValidationCompleted.bind(this)
    this.validateAndFocous = this.validateAndFocous.bind(this)

    this.worker = new Worker('/static/js/codevalidator.js');
    this.worker.addEventListener('message', this.onValidationCompleted, false);
  }

  onValidationCompleted(e) {
      this.props.setEditorLog(e.data);
    }

  onChange(newValue, e) {
    this.worker.postMessage(newValue);
    this.props.codeChanged(newValue);
  }

  editorDidMount(editor, monaco) {
    this.editor = editor;
    this.editor.onDidChangeModel((a, b, c) => {console.log('a', a, 'b', b, 'b', c, 'c')})
    this.validateAndFocous();
  }

  validateAndFocous(code) {
    this.worker.postMessage(code /*this.editor.getValue()*/)
    this.editor.focus();
  }
  componentWillUnmount() {
  }

  render() {
    const { edition, editorHeight } = this.props;
    let code = !edition.code ? '' : edition.code;
    if(this.editor && code != this.editor.getValue()) {
      this.validateAndFocous(code)
    }
    
    const options = {
      selectOnLineNumbers: true
    };
    
    return (<div className="panel panel-default editor editor-panel" style={{marginBottom: 0, border: "1px solid #ddd"}} >
        <div className="panel-body editor-holder">

          <MonacoEditor
            height="500px"
            language="javascript"
            theme="vs-white"
            value={code}
            options={options}
            onChange={this.onChange}
            editorDidMount={this.editorDidMount} 
            requireConfig={{
              url: '/assets/vs/loader.js',
              paths: {vs: '/assets/vs'}}} />
        </div>
      </div>)
  }
}

const stateToProps = (state) => ({
  current: state.adminCurrent
})

export default connect(stateToProps, {setEditorLog})(Editor)