import './index.scss'
import React from 'react'
import {Modal, Button, FormGroup, FormControl, ControlLabel, HelpBlock, Form} from 'react-bootstrap'
import {connect} from 'react-redux'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import {getImgUrl} from '../../../common'
import {fetchImgs, addImg} from '../../actions/imgs'
import alertify from 'alertify.js'

 class EditorImages extends React.Component {
  constructor(props) {
    super(props)

    this.close = this.close.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getValidationState = this.getValidationState.bind(this)

    this.state = {
      showModal: false,
      file: null,
      errors: {},
      fetching: false
    }
  }

  componentDidMount() {
    this.props.fetchImgs();
  }

  addNewImage(e) {
    this.setState(prevState => {
      return Object.assign({}, prevState, {showModal: true})
    })
  }

  close() {
    this.setState({
      showModal: false,
      file: null,
      errors: {},
    })
  }
  
  handleSubmit(e) {
    e.preventDefault();
    
      let formData = new FormData();
      formData.append('photo.jpg', this.state.file);
      formData.append('data', JSON.stringify({}))

      this.setState({fetching: true})
      this.props.addImg(formData)
        .then(data => {
          if(data.success) {
            //fetch all
            this.props.fetchImgs()
            .then(() => {
              this.close();
              alertify.success("Image added")
              this.setState({fetching: false})

            })
          } else {
            if(data.errors)
              this.setState({errors: data.errors,
              fetching: false})
          }
          
        })
        .catch((data) => {
          this.close()
          alertify.error("Can't add new image")
          this.setState({fetching: false})
          console.error(data)
        })
  }


  
  handleChange(e) {
    if(e.target.type == 'file') {
      
      let reader = new FileReader();
      let file = e.target.files[0];
  
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
      }
  
      reader.readAsDataURL(file)
    } 
  }

  getValidationState() {
    const {errors} = this.state;
    return isEmpty(errors) ? null : "error";
  }


  render() {
    let { imgs: images, viewport } = this.props;
    let {errors, fetching} = this.state;
  
    images = values(images)

    let h = `${viewport.containerHeight - 110}px`; 
    return (
      <div className="editor-images" style={{height: h}}>
        <div className="panel panel-default editor" >
          <div className="panel-heading editor-actions clearfix">
            <button className="btn btn-primary" onClick={this.addNewImage.bind(this)}>Add new image</button>
          </div>
          <div className="panel-body">

            <ul className="img-list">
              {images.map(i => (
                <li key={i.id}>
                  <img src={getImgUrl(i.path)} />
                  <div className="name">
                    {i.name}
                  </div>
                  {/* <div className="actions pull-right">
                    <a href="#">Edit</a>
                    <a href="#">Delete</a>
                  </div> */}
                </li>
              ))}
            </ul>
          </div>
        </div >

        <Modal show={this.state.showModal} onHide={this.close.bind(this)} animation={false}>
          <Form onSubmit={this.handleSubmit}>
          
          <Modal.Header closeButton>
            <Modal.Title>Add new image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <FormGroup
                controlId="imgForm" validationState={this.getValidationState()}>

                <FormControl

                  type="file"
                  name="file"
                  onChange={this.handleChange.bind(this)}
                />
                {/* <FormControl.Feedback /> */}
                {errors['img'] &&
                  <HelpBlock>{errors['img']}</HelpBlock> 
                }
              </FormGroup>

            
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" bsStyle="primary" disabled={fetching}>Save</Button>
            <Button onClick={this.close}>Close</Button>
          </Modal.Footer>
          </Form>
        </Modal>
      </div>
    )
  }
}


const stateToProps = (state) =>({
  imgs: state.imgs,
  viewport: state.viewport
})
export default connect(stateToProps, {fetchImgs, addImg})(EditorImages)