import './index.scss'
import React from 'react'
import {connect} from 'react-redux'
import { Table, Button, Panel, ButtonToolbar, Clearfix } from 'react-bootstrap'
import {fetchRules} from '../../actions/rules'
import EditRuleModal from '../Modals/EditRule';
import { withRouter } from 'react-router-dom'
import {getImgUrl} from '../../../common'
import _values from 'lodash/values';
import {convertDate} from '../../../services/utils'
import AppNav from '../AppNav'

class RulePage extends React.Component {
  constructor(props) {
    super(props)
    this.onEditClicked = this.onEditClicked.bind(this)
    this.onEditEditionClicked = this.onEditEditionClicked.bind(this)

    this.onAddNewRuleClicked = this.onAddNewRuleClicked.bind(this)
    this.onHideEditorRuleModal = this.onHideEditorRuleModal.bind(this)
    this.onSaved = this.onSaved.bind(this)

    this.state = {
      showEditorRuleModal: false,
      editRuleId: undefined
    }
  }

  onSaved(newRuleId) {
    this.setState({
      showEditorRuleModal: false,
      editRuleId: undefined
    })

    this.props.fetchRules();
  
  }

  onAddNewRuleClicked() {
    this.setState({
      editRuleId: undefined,
      showEditorRuleModal: true})
  }

  onEditClicked(ruleId) {
    this.setState({
      editRuleId: ruleId,
      showEditorRuleModal: true})
  
  }
  
  onEditEditionClicked(e) {
    e.priventDefault();
  }
  
  onHideEditorRuleModal() {
    this.setState({
      editRuleId: undefined,
      showEditorRuleModal: false})
  }   

  componentDidMount() {
    this.props.fetchRules();
  }

  render() {
    const {rules: rulesInfo } = this.props;
    const { showEditorRuleModal, editRuleId } = this.state;
    const editRule = editRuleId ? rulesInfo.find(r => r.id == editRuleId) : {};
    

    const EditEditionButton = withRouter((props) => {
      const {history, ruleId} = props;
      return (<Button
        bsStyle="primary"
        onClick={() => { history.push('/admin/rule/' + ruleId) }}
      >
      Edit editions
      </Button>)
    })
    
    return (
      <div>
        <AppNav/>
              <div className="rules-page">
        
        <Panel header={
          <div>
          <ButtonToolbar className="pull-left">
            <Button bsStyle="primary" onClick={this.onAddNewRuleClicked}>Add new</Button>
          </ButtonToolbar>
          <Clearfix/>
          </div>
            }>
          <Table striped condensed hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Front img</th>
                <th>Title/Rules Ref</th>
                <th>Responsible section</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Update By</th>
                <th>Updated At</th>
                <th>Is Public</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rulesInfo.map(ri => (
                <tr key={ri.id}>
                  <td>{ri.id}</td>
                  <td className="front-img"><img alt={ri.img} src={getImgUrl(ri.img)} /></td>
                  <td><div className="fontBold">{ri.title}</div><div className="ruleRef">{ri.slug}</div></td>
                  <td>{ri.resSection}</td>
                  <td>{ri.createdBy}</td>
                  <td className="rulesDate">{convertDate(ri.createdAt)}</td>
                  <td>{ri.updatedBy}</td>
                  <td className="rulesDate">{convertDate(ri.updatedAt)}</td>
                  <td>{ri.isPublic?.toString()}</td>
                  <td className="actions">{
                  <ButtonToolbar>
                    <Button bsStyle="primary" onClick={() => this.onEditClicked(ri.id)}>Edit rule</Button>
                    <EditEditionButton ruleId={ri.id}/>
                  </ButtonToolbar>}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel>
        <EditRuleModal 
          editRule={editRule} 
          show={showEditorRuleModal} 
          onHide={this.onHideEditorRuleModal} 
          onSaved={this.onSaved} />
      </div>
      </div>)
  }

}

const mapStateToProps = (state) => {
  return ({
  rules: _values(state.adminRules.toJS(), ['id'], ['desc']).sort((a, b) => (b.id < a.id)),
})}

export default connect(
  mapStateToProps,
  {fetchRules}
)(RulePage)