const style = `
.preline {
  white-space: pre-line;
}
body, td {
font-family: sans-serif;
font-weight: 300;
font-size: 12pt;
}
td:nth-child(1) {
width: 8em;
text-align: right;
}
td:nth-child(2) {
min-width: 12em;
}
td:nth-child(3) {
min-width: 6em;
}
h1, h2 {
margin-bottom: 0;
}
h2.table {
  font-size: 12pt;
}
td.var {
  font-size: 10pt;
}
td.val {
  font-size: 10pt;
  font-weight: bold;
}
td.desc {
  font-size: 10pt;
  font-style: italic;
}
.notok {
  color: red;
}
.ok {
color: green;
}
th {
text-align: left;
font-weight: 400;
text-decoration: underline;
}
.header {
  
}
.header:after {
  content: '';
  display: block;
  clear: both;
}

.header .info {
  margin-top: 16px;
  float: left;
  width: 60%;
}

.header .info .info-item {
  display: block;
  margin-bottom: 3px;
}

.header .info .info-item.date {
  font-weight: 600;
  margin-bottom: 6px;
}

div.wrapper {
      max-width: 210mm;
      margin: 1em auto;
      box-shadow: #ccc 2px 3px 5px 5px;
      padding: 1em;
      box-sizing: border-box;
      position: relative;
}
#logo {
  display: block;
  float: right;
  width: 130px;
  height: 70px;
}
button.print-btn {
  position: fixed;
  top: 10px;
  left: 10px;

}
@media print {
  div.wrapper {
    box-shadow: none;
  }

  button.print-btn {
    display: none;
  }
}
`

export default class Report {
  constructor(user, output, program, tree, errors, warnings) {
    this.program = program
    this.tree = tree
    this.errors = errors;
    this.warnings = warnings;
    this.title = 'Pre Approval Screening Service';
    this.o = output;
    this.user = user || {}

  }

  getDate() {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }

    return {
      dd,
      mm,
      yyyy,
      epoch: today.getTime()
    }
  }

  blocks() {
    return `${this.o.userinput.map(ui => this.block(ui)).join("")}`
  }

  block(ui) {
    return `<div>
          <h2 class="table">${ui.title}</h2><table>
          <tbody>
          ${ui.items.map(it => this.item(it)).join("")}
          </tbody>
          </table>
        </div>`
  }

  item(it) {
    let val = it.value;
    let u = this.program.back[it.name].src
    if (typeof val === "function") {
      val = "func"
    }
    if (Array.isArray(val) || typeof val === "object")
      val = JSON.stringify(val)
    return `<tr>
          <td class="var">${it.name} = </td>
          <td class="val">${val}</td>
          <td class="desc">${it.desc == undefined ? "" : it.desc}</td>
        </tr>`
  }

  crow(c) {
    let val = c.value;
    if (isFinite(val)) {
      val = (new Number(val)).toPrecision(4);
    }
    return `<tr>
        <td class="var">${(c.assoc != "" && c.assoc != undefined) ? "<i>[" + c.assoc + "]-</i>" : ""}${c.name} = </td>
        <td class="val">${c.value}</td>
        <td class="desc">${c.ref}</td>
      </tr>`
  }

  calc() {
    return `<div>
            <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Ref.</th>
              </tr>
            </thead>
            <tbody>
              ${this.o.calculated.map(c => this.crow(c)).join("")}
            </tbody>
            </table>
          </div>`
  }

  rule(r, i) {
    return `<tr>
        <td class="desc" style="text-align: left;">${r.ref}${(r.assoc == undefined || r.assoc == "") ? "" : "-[" + r.assoc + "]"}:</td>
        <td class="var" style="width: 1em;"></td>
        <td class="desc">
        ${
      r.disps.map(v => {
        return `<div class="preline ">${v}</div>`
      }).join("")
      }
        ${
      r.errors == undefined ? "" : r.errors.map(v => {
        return `<div class="preline notok">${v}</div>`
      }).join("")
      }
        ${r.warnings == undefined ? "" :
        r.warnings.map(v => {
          return `<div class="preline">${v}</div>`
        }).join("")
      }</td>
      </tr>`
  }

  rules() {
    return `<div>
          <table>
          <thead>
            <tr>
              <th style="min-width: 14em; text-align: left;"></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            ${this.o.rulestatus.map((r, i) => this.rule(r, i)).join("")}
          </tbody>
          </table>
        </div>`
  }

  toString() {
    let haserr = (this.errors.length > 0)
    let now = this.getDate()
    let nowDate = `${now.yyyy}-${now.mm}-${now.dd}`
    return `
<!doctype html>
<html>
<head>
  <meta charset="utf-8">
  <title>Calculations ${this.title}</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0, maximum-scale=1, minimum-scale=1">
  <link rel="stylesheet" type="text/css" href="///cdnjs.cloudflare.com/ajax/libs/KaTeX/0.6.0/katex.min.css">
  <style>${style}</style>
</head
<body>
    <div class="wrapper">
        <button type="button" class="print-btn" onclick="printReport();return false;">Print...</button>
        <div class="header">
          <div class="info">
            <span class="info-item date">${nowDate}</span>
            <span class="info-item">Nr. pass-${now.epoch}</span>
            <span class="info-item">Generated by: ${this.user.name}</span>
          </div>
          <img id="logo" src="/static/imgs/DNV_logo_RGB.png">
        </div>
        <h2>Pre Approval Screening Report. Only calculation as submitted has been checked against referenced rules. Complete approval of the component can be finalized only after drawings are reviewed and correspondence with calculation is verified. Additional comments may raise during the process.</h2>
        <h3>${this.program.name} - ${this.program.edition}</h3>
        <p>Reference standards: ${this.program.desc}</p>
        <p><em>Rule check status: </em><em class="${haserr ? "notok" : "ok"}">${haserr ? "NOT OK" : "OK"}</em></p>
        <h2>User input</h2>
          <p>Below listed are the input values provided by user which were the basis for evaluation against refernce standard.</p>
          ${this.blocks()}
        <h2>Calculated values</h2>
          <p>These are the values resulting from application of referenced rules to the submitted calculation. At your convenience they may be used to double check the calculations or to see how close to the limit the given design is.</p>
          ${this.calc()}
        <h2>Rule status - <span class="${haserr ? "notok" : "ok"}">${haserr ? "NOT OK" : "OK"}</span></h2>
          <p>Below the list of applied standards/rules and relevant status (OK or description of non compliance).</p>
          ${this.rules()}
      </div>

      <script type="text/javascript">
      //<![CDATA[
        function printReport() {
          if (document.queryCommandSupported('print')) {
            document.execCommand('print', false, null);
          }
          else {
            //window.parent.<framename>.focus();
            window.print();
          }
        }
      //]]>
      </script>
</body></html>`
  }
}