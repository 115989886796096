import './index.scss'
import React from 'react'
import { Table, Checkbox } from 'react-bootstrap'
import api from '../../services/api'
import filter from 'lodash/filter'
import alertify from 'alertify.js'
import AppNav from '../AppNav';

export default class UserPage extends React.Component {

  constructor(props) {
    super(props)
  
    this.onSuperUserFlagChanged = this.onSuperUserFlagChanged.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.filterUsers = this.filterUsers.bind(this)
    
    this.state = {
      users: [],
      search: ''
    }
  }

  componentDidMount() {
    api.user.all().then(data => {
      this.setState({users: data});
    })
    .catch(err => {
      console.error(err)
    })
  }

  onSuperUserFlagChanged(event, userId) {
    event.preventDefault();
    const that = this;
    let users = this.state.users;
    const newValue = event.target.checked;
    alertify.confirm('Are you sure you want to change this value?', function(){ 
      api.user.updateAdminFlag(userId, newValue)
        .then((data) => {
          var newUsers = users.map(u => {
            if(u.id == data.id)
              return data
            
              return u;
          })
          that.setState({users: newUsers });
          alertify.success('Value changed');
        })
        .catch(err => {
          console.error(err);

          alertify.error('Can not change value')
        })
      
      });
  }

  onSearchChange(e) {
    this.setState({search: e.target.value});
  }

  filterUsers(users, search) {
    if(!search)
      return users;
    
    return users.filter((u) => 
      (u.name.toLowerCase().includes(search.toLowerCase()) ||
      u.email.toLowerCase().includes(search.toLowerCase()))
    );
  }

  render() {
    const { users, search } = this.state;
    const filterUsers = this.filterUsers(users, search)
    return (
      <div>  
        <AppNav/>
    <div className="user-page">

    <div className="form-group">
      <input type="text" className="form-control" placeholder="Search..." onChange={this.onSearchChange} />
    </div>
    
    <Table striped condensed hover>
    <thead>
      <tr>
        <th>#</th>
        <th>Name</th>
        <th>Email</th>
        <th>Is Admin</th>
      </tr>
    </thead>
    <tbody>
      {filterUsers.map((u, idx) => (
        <tr key={u.id}>
          <td>{idx + 1}</td>
          <td>{u.name}</td>
          <td>{u.email}</td>
          <td><Checkbox checked={u.isSuperUser} onChange={(e) => this.onSuperUserFlagChanged(e, u.id)} /></td>
        </tr>))}
      </tbody>
      </Table>
      </div>
      </div>)      
  }
}
