import './index.scss'
import React from 'react'
import {connect} from 'react-redux'
import AppNav from '../AppNav'

class App extends React.Component {
  render() {
    
    const {viewport} = this.props;
    if(!(viewport && viewport.height))
      return null;    
    
    const containerHeight = `${viewport.containerHeight}px`
    return (
        <div className="App">
          <AppNav />
          <div style={{height: containerHeight}}>
          <div className="container-fluid app-content-holder" style={{height: "100%"}} />
          </div>
        </div>)
  }
}

const stateToProps = (state) => ({
  viewport: state.AdminViewport
})
export default connect(stateToProps)(App)


