import './index.scss'
import React from 'react'
import alertify from 'alertify.js'

export default class RuleSubMenu extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    this.subMenu.classList.remove("open");
    this.subMenu.childNodes[0].setAttribute("aria-expanded", "false");
  }

  newProjectOnClick(e){
    let {onNewProject} = this.props;
    onNewProject();
  }

  render() {
    const { onSaveCurrentInput, onSaveNewProject, onDownloadInput, onDownloadExcel, onInportData, currentProject, onGenerateReport } = this.props
    return (
      <div className="btn-group rule-submenu" 
         ref={(el) => { this.subMenu = el; }} 
      >
        <button className="btn btn-default" onClick={(e)=>{e.preventDefault(); this.newProjectOnClick(e)}}>New Project <span className="glyphicon glyphicon-plus" aria-hidden="true"></span></button>
        <button
          type="button"
          className="btn btn-default dropdown-toggle"
          data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <span className="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
          {currentProject &&
            <li><a href="#" onClick={(e) => { e.preventDefault(); onSaveCurrentInput()}} ><span className="custom-icon glyphicon glyphicon-check save-current-input-icon" aria-hidden="true"></span> Save current input</a></li>
          }
          <li><a href="#" onClick={(e) => { e.preventDefault(); onSaveNewProject()}}><span className="custom-icon glyphicon glyphicon-floppy-disk save-new-project-icon" aria-hidden="true"></span> Save as new project</a></li>
          <li><a href="#" onClick={(e) => { e.preventDefault();  onDownloadInput()}}><span className="custom-icon	glyphicon glyphicon-save download-input-icon" aria-hidden="true"></span> Download input</a></li>
          <li><a href="#" onClick={(e) => { e.preventDefault();  onDownloadExcel()}}><span className="custom-icon glyphicon glyphicon-save-file download-excel-icon" aria-hidden="true"></span> Download .xls</a></li>
          <li>
            <div className="inputWrapper">
              <input className="fileInput" type="file" name="file1" onChange={onInportData} onClick={this.onClick} style={{opacity: 0}}/>
              <span className="custom-icon glyphicon glyphicon-folder-open" aria-hidden="true"></span><span>Import data</span>
            </div>
            
          </li>
          {/* <li><a href="#" onClick={(e) => { e.preventDefault(); onInportData()}}><span className="custom-icon immport-data-icon" aria-hidden="true"></span> Import data</a></li> */}
          <li><a href="#" onClick={(e) => { e.preventDefault(); onGenerateReport()}}><span className="custom-icon glyphicon glyphicon-stats generate-report-icon" aria-hidden="true"></span> Generate report</a></li>
        </ul>
      </div >
    )
  }
}